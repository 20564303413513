import { Button, Form, FormInstance } from 'antd';
import moment from 'moment-timezone';
import React, { RefObject } from 'react';
import ProviderGenderPreferences from '../../../../consts/ProviderGenderPreferences';
import HasAppointmentOptions from '../../../../consts/ProviderNominationHasAppointmentOptions';
import Guid from '../../../../utils/Guid';
import AppointmentFormListItem, { MemberLookup } from './AppointmentFormListItem';

interface AppointmentFormListProps {
  formRef: RefObject<FormInstance<any>>;
  familyMembers: MemberLookup[];
}

const AppointmentFormList: React.FC<AppointmentFormListProps> = (props) => {
  const defaultTimezone = moment.tz.guess();

  return (
    <Form.List name="appointments">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <AppointmentFormListItem
              key={key}
              index={key}
              formRef={props.formRef}
              familyMembers={props.familyMembers}
              formListRemove={remove}
              formListName={name}
              restField={restField}
            />
          ))}
          <Form.Item>
            <Button
              type="ghost"
              size="large"
              shape="round"
              onClick={() =>
                add({
                  id: Guid.NewGuid(),
                  existing: false,
                  appointmentTimeZone: defaultTimezone,
                  genderPreference: ProviderGenderPreferences.NONE,
                  hasAppointment: HasAppointmentOptions.NO,
                })
              }
            >
              Select Family Member
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default AppointmentFormList;
