import { Button, Checkbox, DatePicker, Form, Input, notification, Radio, Table, Tooltip, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FormInstance, Rule } from 'antd/lib/form';
import { ColumnProps, TablePaginationConfig, TableProps } from 'antd/lib/table/';
import moment from 'moment';
import * as React from 'react';
import { useContext } from 'react';
import TableColumnState from '../../../models/TableColumnState';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableRequestFilterDTO from '../../../models/TableRequestFilterDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import NumberInput from '../NumberInput';
import Time24Hour from '../Time24Hour';
import './DataTable.less';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  FilterFilled,
  FilterOutlined,
  SearchOutlined,
} from '@ant-design/icons';

const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

export enum FilterType {
  NONE,
  Text,
  Date,
  DateRange,
  DropdownSingle,
  DropdownMulti,
  BooleanCheckbox,
  BooleanRadio,
}

export enum ColumnType {
  Text = 1,
  Date,
  Number,
  Boolean,
}

export enum EditType {
  Hidden,
  Text,
  Date,
  Time,
  Number,
  Dropdown,
  BooleanCheckbox,
  BooleanToggle,
  BooleanRadio,
  CUSTOM,
}

export interface DataTableData {
  isTotalRow: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ColumnEditOptions<T> {
  editable: boolean;
  disabled?: boolean;
  required?: boolean;
  type?: EditType;
  decimalPrecision?: number;
  dropdownOptions?: { text: string; value: string }[];
  inputStyle?: React.CSSProperties;
  inputClass?: string;
  placeholder?: string;
  onChange?: (value: any, form: React.RefObject<FormInstance>) => void;
  rules?: Rule[];
  customEditor?: (form: FormInstance, record: any, props: any) => any;
  additional?: any;
}

export interface DataTableStyleOptions {
  bordered?: boolean;
  alternatingRowHighlight?: boolean;
  compact?: boolean;
}

export interface DataTableColumnProps<T> extends ColumnProps<T> {
  filterType?: FilterType;
  columnType?: ColumnType;
  dropdownFilterOptions?: { text: string; value: string }[];
  editOptions?: ColumnEditOptions<T>;
  renderDataTransform?: (value: any, record: T) => any;
  initialFilterValue?: string | boolean | undefined | null;
  sumRender?: (text: any, record?: T) => any;
  showSum?: boolean;
  // TODO: DisplayPath?
}

export interface DataTableProps<T> {
  serverSide?: boolean;
  data?: T[];
  fetchData?:
    | ((
        requestState: TableRequestDTO,
        checkEcho: () => boolean,
        callback: (response: TableResponseDTO<T>) => void
      ) => any)
    | {
        fetch: (tableRequest: TableRequestDTO) => Promise<TableResponseDTO<T>>;
        success?: (results: T[]) => Promise<void>;
        fail?: (error: any) => Promise<void>;
        failureMessage?: string;
      };
  columns: DataTableColumnProps<T>[];
  title?: string | JSX.Element;
  buttonBar?: DataTableButton[];
  tableProps: TableProps<T>;
  stateSaving?: {
    enabled: boolean;
    tableUniqueKey: string;
    perSession?: boolean;
  };
  editOptions?: {
    allowEdit?: boolean;
    fullTableEdit?: boolean;
    useIconButtons?: boolean;
    rowClickForEdit?: boolean;
    saveRow?: (record: T, editValues: T, callback: (success: boolean, updatedRows?: T[]) => void) => void;
    cancelEdit?: () => void;
  };
  styleOptions?: DataTableStyleOptions;
  selectable?: boolean;
  selectVisibility?: (row: T) => boolean;
  paginationPosition?: ('topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight')[];
  showSum?: boolean;
  globalSearch?: boolean;
  globalSearchPlaceholder?: string;
  onRowClick?: (record: T) => void;
  wrapperClassName?: string;
}

export enum DataTableButtonType {
  Generic,
  Link,
  Export,
  Reset,
}

export interface DataTableButton extends ButtonProps {
  dataTableButtonType: DataTableButtonType;
  label: string;
}

export interface ExportButton extends DataTableButton {
  export: (tableState: TableRequestDTO) => any;
}

export interface ResetButton extends DataTableButton {}

export interface LinkButton extends DataTableButton {
  to: string;
}

const EditableContext = React.createContext({} as FormInstance);

interface EditableRowProps {
  index: number;
  edit: (record: any, form?: FormInstance, index?: number, saveOnClickOutside?: boolean) => void;
  canEdit: boolean;
  record: any;
  className: string;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, edit, canEdit, record, className, ...props }: any) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false} initialValues={record}>
      <EditableContext.Provider value={form}>
        <tr
          {...props}
          className={'editable-row ' + (className || '')}
          onClick={(event) => {
            if (
              edit &&
              canEdit &&
              event &&
              (event.target as HTMLInputElement).type === 'checkbox' &&
              (event.target as HTMLInputElement).disabled !== true
            ) {
              setTimeout(() => edit(record, form, index, false), 0);
            }
          }}
          onBlur={(event) => {
            const parent = commonAncestor(event.target, event.relatedTarget);
            if (
              edit &&
              canEdit &&
              event &&
              (event.target as any).type === 'text' &&
              (event.target as any).disabled !== true &&
              (!parent || (parent.nodeName !== 'TD' && parent.nodeName !== 'TR'))
            ) {
              setTimeout(() => edit(record, form, index, false), 0);
            }
          }}
        />
      </EditableContext.Provider>
    </Form>
  );
};

function parents(node: any) {
  const nodes = [node];
  for (; node; node = node.parentNode) {
    nodes.unshift(node);
  }
  return nodes;
}

function commonAncestor(node1: any, node2: any) {
  const parents1 = parents(node1);
  const parents2 = parents(node2);

  if (parents1[0] !== parents2[0]) {
    return null;
  }

  for (let i = 0; i < parents1.length; i++) {
    if (parents1[i] !== parents2[i]) {
      return parents1[i - 1];
    }
  }
}

const EditableFormRow = EditableRow;

interface EditableCellProps {
  index: number;
  edit: (record: any, form?: FormInstance, index?: number, saveOnClickOutside?: boolean) => void;
  canEdit: boolean;
  record: any;
  className?: string;
  editing: boolean;
  dataIndex: string | number | (string | number)[];
  title?: string;
  inputType?: EditType;
  inputStyle?: React.CSSProperties;
  inputClass?: string;
  inputSize?: 'small' | 'large' | 'default' | undefined;
  decimalPrecision?: number;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  renderDataTransform?: (value: any, record: any) => any;
  customEditor?: any; // (form: React.RefObject<FormInstance>, record: any, props: any) => any;
  additional?: any;
  onChange?: (value: any, form: FormInstance) => void;
  onEnter?: (form: FormInstance, record: any) => void;
  onEscape?: () => void;
}

const getRecordValue = (record: any, dataIndex: string | number | (string | number)[]) => {
  if (!record || !dataIndex) {
    return null;
  }

  let value = record;

  if (Array.isArray(dataIndex)) {
    dataIndex.forEach((d) => {
      value = value[d];
    });
  } else {
    value = record[dataIndex];
  }

  return value;
};

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  inputStyle,
  inputClass,
  inputSize,
  decimalPrecision,
  placeholder,
  disabled,
  required,
  renderDataTransform,
  record,
  index,
  customEditor,
  additional,
  onChange,
  onEnter,
  onEscape,
  className,
  ...restProps
}) => {
  // SEE: https://ant.design/components/table/#components-table-demo-edit-row

  const form = useContext(EditableContext);
  const recordValue = getRecordValue(record, dataIndex);

  let editor: React.ReactElement;
  switch (inputType) {
    case EditType.Hidden:
      editor = (
        <React.Fragment>
          <Input type="hidden" />
          {recordValue}
        </React.Fragment>
      );
      break;
    case EditType.Number:
      editor = (
        <NumberInput
          onChange={(value) => (onChange ? onChange(value, form) : null)}
          onKeyUp={(e) => handleKeyUp(e)}
          disabled={!!disabled}
          precision={decimalPrecision}
          placeholder={placeholder}
          size={inputSize}
          className={inputClass}
          style={{ ...inputStyle }}
          {...additional}
        />
      );
      break;
    case EditType.Time:
      editor = (
        <Time24Hour
          onChange={(time) => (onChange ? onChange(time, form) : null)}
          format="h:mm a"
          minuteStep={15}
          disabled={!!disabled}
          size={inputSize}
          className={inputClass}
          style={{ ...inputStyle }}
          {...additional}
        />
      );
      break;
    case EditType.BooleanCheckbox:
      editor = (
        <Checkbox
          onChange={(e) => (onChange ? onChange(e.target.checked, form) : null)}
          disabled={!!disabled}
          className={inputClass}
          style={{ ...inputStyle }}
          {...additional}
        />
      );
      break;
    case EditType.CUSTOM:
      if (!customEditor) {
        console.error('CustomEditor Props not found.');
        editor = <span style={{ color: 'red' }}>Error</span>;
        break;
      }
      // editor = customEditor.getInput(recordValue, form, record, {
      editor = customEditor(form, record, {
        editing,
        dataIndex,
        title,
        inputType,
        inputStyle,
        inputClass,
        inputSize,
        decimalPrecision,
        placeholder,
        disabled,
        required,
        renderDataTransform,
        record,
        index,
        customEditor,
        additional,
        onChange,
        onEnter,
        onEscape,
        className,
        ...restProps,
      });
      break;
    default:
      editor = (
        <Input
          onChange={(e) => (onChange ? onChange(e.target.value, form) : null)}
          // onPressEnter={(e) => onEnter(form, record)}
          onKeyUp={(e) => handleKeyUp(e)}
          disabled={!!disabled}
          size={inputSize}
          placeholder={placeholder}
          className={inputClass}
          style={{ ...inputStyle }}
          {...additional}
        />
      );
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        if (onEnter) {
          onEnter(form, record);
        }
        break;
      case 'Esc': // IE/Edge specific value
      case 'Escape':
        if (onEscape) {
          onEscape();
        }
        break;
      default:
        return;
    }
  };

  let valPropName = undefined;
  let displayComp = undefined;

  if (editing) {
    valPropName = 'value';
    if (
      inputType === EditType.BooleanCheckbox ||
      inputType === EditType.BooleanRadio ||
      inputType === EditType.BooleanToggle
    ) {
      valPropName = 'checked';
    }

    if (inputType === EditType.CUSTOM) {
      if (!customEditor) {
        displayComp = <span style={{ color: 'red' }}>Error</span>;
      } else {
        displayComp = customEditor(form, record, {
          onChange: onChange ? (e: any) => onChange(e.target.value, form) : null,
          onKeyUp: (e: any) => handleKeyUp(e),
          onPressEnter: () => {
            if (onEnter) {
              onEnter(form, record);
            }
          },
        }); // this.props);
      }
    } else {
      displayComp = (
        <FormItem
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName={valPropName}
          rules={[{ required: required, message: '*' }]}
        >
          {editor}
        </FormItem>
      );
    }
  } else {
    displayComp = restProps.children;
  }

  return (
    <td className={(editing ? 'editable-cell ' : '') + (className || '')} {...restProps}>
      {record && record.isTotalRow && inputType !== EditType.CUSTOM ? <></> : displayComp}
    </td>
  );
};

export interface DataTablePaginationProps extends TablePaginationConfig {
  dataTableUnfilteredTotal?: number | undefined;
}

interface DataTableState<T> {
  pagination: DataTablePaginationProps;
  sumResult: T | null;
  sorter: { columnKey: string; field: string; order: string } | any;
  filters: TableRequestFilterDTO[];
  globalFilter: string | null;
  columnStates: TableColumnState[];
  data: T[];
  filteredData: T[] | null;
  editingRecord: T | null;
  editingIndex?: number | null;
  loading?: boolean;
}

class DataTable<T> extends React.Component<DataTableProps<T>, DataTableState<T>> {
  public static DefaultPageSize = 25;
  public static DefaultPageSizeOptions = ['25', '50', '75', '100'];

  private searchInputs: any = {};
  private echo = 0;
  private editingRowForm!: FormInstance;
  private selectedRowIds: any[] = [];

  constructor(props: DataTableProps<T>) {
    super(props);

    let tableState: DataTableState<T> = {} as any;
    if (props.stateSaving && props.stateSaving.enabled) {
      const tableStateStr = props.stateSaving.perSession
        ? sessionStorage.getItem(this.getStateSavingTableKey())
        : localStorage.getItem(this.getStateSavingTableKey());
      if (tableStateStr) {
        tableState = JSON.parse(tableStateStr);

        const columnKeys = this.props.columns.map((c) => c.dataIndex || c.key);
        if ((tableState.filters || []).some((f) => !columnKeys.includes(f.columnProp || ''))) {
          tableState = {} as any;
        } else if (tableState.sorter.columnKey && !columnKeys.includes(tableState.sorter.columnKey || '')) {
          tableState = {} as any;
        } else if ((tableState.columnStates || []).some((f) => !columnKeys.includes(f.columnProp || ''))) {
          tableState = {} as any;
        }
      }
    }

    const defaultSorter: { columnKey: string; field: string; order: string } | any = {};
    if ((props.columns || []).some((c) => c.defaultSortOrder)) {
      const sortColumn = (this.props.columns || []).find((c) => c.defaultSortOrder);
      if (sortColumn) {
        defaultSorter.columnKey = sortColumn.dataIndex || sortColumn.key;
        defaultSorter.field = sortColumn.dataIndex || sortColumn.key;
        defaultSorter.order = sortColumn.defaultSortOrder;
      }
    }

    this.state = {
      pagination:
        this.props.tableProps && this.props.tableProps.pagination === false
          ? {
              hideOnSinglePage: true,
              pageSize: 1000000,
            }
          : {
              defaultPageSize: (this.props.tableProps?.pagination as any)?.defaultPageSize || DataTable.DefaultPageSize,
              pageSizeOptions:
                (this.props.tableProps?.pagination as any)?.pageSizeOptions || DataTable.DefaultPageSizeOptions,
              defaultCurrent: 1,
              position: this.props.paginationPosition || ['bottomRight'],
              showSizeChanger:
                (this.props.tableProps?.pagination as any)?.showSizeChanger !== undefined
                  ? (this.props.tableProps?.pagination as any)?.showSizeChanger
                  : true,
              size: (this.props.tableProps?.pagination as any)?.size || undefined,
              showLessItems: (this.props.tableProps?.pagination as any)?.showLessItems || undefined,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total}
                                ${
                                  this.state.pagination.dataTableUnfilteredTotal &&
                                  this.state.pagination.dataTableUnfilteredTotal !== total
                                    ? 'filtered from ' + this.state.pagination.dataTableUnfilteredTotal + ' total'
                                    : 'total'
                                }`,
              // showTotal: (total, range) =>
              //   this.state.pagination.dataTableUnfilteredTotal &&
              //   this.state.pagination.dataTableUnfilteredTotal !== total ? (
              //     <Translate
              //       id="datatables.filtering"
              //       data={{
              //         numberStart: range[0],
              //         numberEnd: range[1],
              //         totalNumber: total,
              //         totalNonFiltered: this.state.pagination.dataTableUnfilteredTotal,
              //       }}
              //     />
              //   ) : (
              //     <Translate
              //       id="datatables.showing"
              //       data={{ numberStart: range[0], numberEnd: range[1], totalNumber: total }}
              //     />
              //   ),
            },
      sumResult: props.serverSide ? null : props.showSum ? this.getLocalSumResult(props.data || []) : null,
      sorter: props.stateSaving && props.stateSaving.enabled && tableState.sorter ? tableState.sorter : defaultSorter,
      filters:
        props.stateSaving && props.stateSaving.enabled && tableState.filters
          ? tableState.filters
          : this.props.columns
              .filter((column) => column.initialFilterValue !== null && column.initialFilterValue !== undefined)
              .map((column) =>
                TableRequestFilterDTO.create({
                  columnProp: column.dataIndex || column.key,
                  filter: column.initialFilterValue,
                })
              ),
      globalFilter: null, // Don't remember the global filter
      // globalFilter: props.stateSaving && props.stateSaving.enabled && tableState.globalFilter ? tableState.globalFilter : null,
      columnStates:
        props.stateSaving &&
        props.stateSaving.enabled &&
        tableState.columnStates &&
        tableState.columnStates.length === this.props.columns.length
          ? tableState.columnStates
          : this.props.columns.map((column) => {
              return TableColumnState.create({
                columnProp: column.dataIndex || column.key,
                columnType: column.columnType,
                initialSearchValue: column.initialFilterValue,
                searchText:
                  column.initialFilterValue !== null && column.initialFilterValue !== undefined
                    ? (column.initialFilterValue as string)
                    : '',
                filtered: column.initialFilterValue !== null && column.initialFilterValue !== undefined,
                renderDataTransform: column.renderDataTransform,
              });
            }),
      data: [],
      filteredData: null,
      editingRecord: null,
      loading: false,
    };

    // this.search = debounce(this.search, 400);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    if (this.props.serverSide) {
      this.callFetchData(this.state.pagination, this.state.filters, this.state.sorter, this.state.globalFilter);
    } else {
      this.setState({
        data: this.props.data || [],
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<DataTableProps<T>>): void {
    this.props.columns.forEach((column) => {
      if (!prevProps.columns.some((oldColumn) => oldColumn.title == column.title)) {
        this.addColumnState(column);
      }
    });
  }

  resetTable() {
    const pager = { ...this.state.pagination };
    pager.current = 1;

    const columnStates = this.state.columnStates.map((colState) => {
      colState.filtered = colState.initialSearchValue !== null && colState.initialSearchValue !== undefined;
      colState.filterVisible = false;
      colState.searchText =
        colState.initialSearchValue !== null && colState.initialSearchValue !== undefined
          ? (colState.initialSearchValue as string)
          : '';
      return colState;
    });

    const defaultSorter: { columnKey: string; field: string; order: string } | any = {};
    if ((this.props.columns || []).some((c) => c.defaultSortOrder)) {
      const sortColumn = (this.props.columns || []).find((c) => c.defaultSortOrder);
      if (sortColumn) {
        defaultSorter.columnKey = sortColumn.dataIndex || sortColumn.key;
        defaultSorter.field = sortColumn.dataIndex || sortColumn.key;
        defaultSorter.order = sortColumn.defaultSortOrder;
      }
    }

    this.setState(
      {
        pagination: pager,
        sorter: defaultSorter,
        filters: columnStates
          .filter((column) => column.initialSearchValue !== null && column.initialSearchValue !== undefined)
          .map((column) =>
            TableRequestFilterDTO.create({
              columnProp: column.columnProp,
              filter: column.initialSearchValue,
            })
          ),
        globalFilter: null,
        columnStates,
      },
      () => this.refresh()
    );

    this.saveTableState({}, [], columnStates, null);
  }

  getSelected() {
    return this.selectedRowIds;
  }

  refresh() {
    if (this.props.serverSide) {
      this.callFetchData(this.state.pagination, this.state.filters, this.state.sorter, this.state.globalFilter);
    } else {
      this.setState(
        {
          data: this.props.data || [],
          // filteredData: this.props.data
          filteredData: null,
          sumResult: this.getLocalSumResult(this.props.data || []),
        },
        () => {
          this.state.columnStates
            .filter((colState) => colState.filtered)
            .forEach((colState) => this.onSearch(colState));
        }
      );
    }
  }

  saveOnClickOutside = (form: FormInstance, record: T, index: number, callback?: (saved: boolean) => void) => {
    const outsideClickListener = (event: any) => {
      if (event.target.closest('.editable-row') === null) {
        if (this.state.editingRecord) {
          this.save(form, record, index, callback);
        }

        // eslint-disable-next-line
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
    };

    document.removeEventListener('click', outsideClickListener);
    document.addEventListener('click', outsideClickListener);
  };

  render() {
    const tableProps = { ...this.props.tableProps };
    tableProps.dataSource = this.state.filteredData || this.state.data;
    tableProps.pagination = this.state.pagination;
    tableProps.size = tableProps.size || 'small';
    tableProps.onChange = this.handleTableChange;
    if (tableProps.loading === undefined) {
      tableProps.loading = this.state.loading;
    }

    let propColumns = this.props.columns.map((a) => a);

    if (this.props.selectable === true) {
      propColumns = [this.getSelectColumn(), ...propColumns];
    }

    let columns = propColumns.map((col, index) => {
      if (!col.editOptions) {
        return col;
      }

      if (!col.editOptions.editable) {
        return col;
      }

      return {
        ...col,
        index,
      } as DataTableColumnProps<T>;
    });

    // if (this.props.showSum && tableProps.dataSource.length > 0) {
    if (this.props.showSum) {
      const sumResult = this.state.sumResult;
      if (sumResult) {
        tableProps.components = {
          body: {
            wrapper: ({ ...p }) => {
              return (
                <tbody {...p}>
                  {
                    <tr className="ant-table-row dtSumRow" key="sumRow">
                      {p.rowSelection ? <td className="ant-table-selection-column" /> : null}
                      {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        columns.map((column, i) => {
                          const dataIndex = column.dataIndex;
                          const colKey = column.key;
                          const colAlign = column.align;

                          let text = sumResult && dataIndex ? (sumResult as any)[dataIndex as any] : undefined;
                          if (column.sumRender) {
                            text = column.sumRender(text, sumResult);
                          } else if (column.columnType !== ColumnType.Number) {
                            text = undefined;
                          } else if (column.render) {
                            text = column.render(text, sumResult, 0);
                          }

                          return (
                            <td key={`sumCol_${dataIndex || colKey}`} style={{ textAlign: colAlign }}>
                              {text}
                            </td>
                          );
                        })
                      }
                    </tr>
                  }
                </tbody>
              );
            },
          },
        };
      }
    }

    // if ((!this.props.editOptions || this.props.editOptions && this.props.editOptions.allowEdit !== false) &&
    if (this.props.editOptions && this.props.columns.some((c) => !!c.editOptions && c.editOptions.editable)) {
      const body: any = tableProps.components ? tableProps.components.body : {};
      tableProps.components = {
        ...tableProps.components,
        body: {
          ...body,
          row: EditableFormRow,
          cell: EditableCell,
        },
      };

      if (this.props.editOptions && this.props.editOptions.rowClickForEdit) {
        // @ts-ignore
        tableProps.onRow = (record: T, index: number) => ({
          record,
          index,
          edit: this.edit,
          canEdit: this.props.editOptions && this.props.editOptions.allowEdit,
        });
      } else if (this.props.editOptions && this.props.editOptions.fullTableEdit) {
        // @ts-ignore
        tableProps.onRow = (record: T, index: number) => ({
          record,
          index,
          edit: this.edit,
          canEdit: this.props.editOptions && this.props.editOptions.allowEdit,
        });
      } else {
        if (this.props.onRowClick) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          tableProps.onRow = (record: T, index?: number) => ({
            onClick: () => (this.props.onRowClick ? this.props.onRowClick(record) : undefined),
          });
        }
        columns.push({
          title: '',
          dataIndex: 'actions',
          render: (text: string, record: T, index: number) => {
            const editable = this.isEditing(record);
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {editable ? (
                  <React.Fragment>
                    <EditableContext.Consumer>
                      {(form) => {
                        form.setFieldsValue(record);
                        return this.props.editOptions && this.props.editOptions.useIconButtons ? (
                          <Button
                            size="small"
                            icon={<CheckOutlined />}
                            style={{ marginRight: 5 }}
                            title="Done"
                            shape="circle"
                            type="primary"
                            onClick={() => this.save(form, record, index)}
                          />
                        ) : (
                          <a
                            href="javascript:;"
                            onClick={() => this.save(form, record, index)}
                            style={{ marginRight: 5 }}
                          >
                            Done
                          </a>
                        );
                      }}
                    </EditableContext.Consumer>
                    {this.props.editOptions && this.props.editOptions.useIconButtons ? (
                      <Button
                        size="small"
                        icon={<CloseOutlined />}
                        shape="circle"
                        danger={true}
                        title="Cancel"
                        onClick={() => this.cancel()}
                      />
                    ) : (
                      <a href="javascript:;" onClick={() => this.cancel()}>
                        Cancel
                      </a>
                    )}
                  </React.Fragment>
                ) : this.props.editOptions && this.props.editOptions.useIconButtons ? (
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    shape="circle"
                    title="Edit"
                    onClick={() => this.edit(record, this.editingRowForm, index)}
                  />
                ) : (
                  <a onClick={() => this.edit(record, this.editingRowForm, index)}>Edit</a>
                )}
              </div>
            );
          },
        });
      }
    } else {
      if (this.props.onRowClick) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tableProps.onRow = (record: T, index?: number) => ({
          onClick: () => (this.props.onRowClick ? this.props.onRowClick(record) : undefined),
        });
      }
    }

    let compact = this.props.styleOptions ? this.props.styleOptions.compact : false;
    compact = false; // DISABLING THIS FOR NOW

    columns = columns.map((col) => {
      if (!col.editOptions) {
        return col;
      }

      if (!col.editOptions.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: T) => ({
          record,
          inputType: col.editOptions ? col.editOptions.type : '',
          dataIndex: col.dataIndex,
          title: col.title as any,
          // editing: this.isEditing(index),
          inputStyle: col.editOptions ? col.editOptions.inputStyle : {},
          inputClass: col.editOptions ? col.editOptions.inputClass : '',
          inputSize: compact ? 'small' : undefined,
          decimalPrecision: col.editOptions ? col.editOptions.decimalPrecision : undefined,
          placeholder: col.editOptions ? col.editOptions.placeholder : undefined,
          customEditor: col.editOptions ? col.editOptions.customEditor : null,
          additional: col.editOptions ? col.editOptions.additional : null,
          disabled: col.editOptions ? col.editOptions.disabled : false,
          required: col.editOptions ? col.editOptions.required : false,
          onChange: (col.editOptions ? col.editOptions.onChange : null) as any,
          onEnter: this.save,
          onEscape: this.cancel,
          renderDataTransform: col.renderDataTransform,
        }),
      };
    });

    tableProps.columns = columns.map((column) => {
      const columnProps = { ...column } as ColumnProps<T>;
      const columnState = this.getColumnState(column);

      // if (!column.key) {
      //     column.key = column.dataIndex;
      // }
      let columnFilterValid = true;
      switch (column.filterType) {
        case FilterType.Text:
          columnProps.filterDropdown = this.textFilter(columnState, column.title);
          columnProps.onFilterDropdownVisibleChange = this.onFilterVisibleChangeWithFocus(columnState);
          break;
        case FilterType.Date:
          columnProps.filterDropdown = this.dateFilter(columnState, column.title);
          columnProps.onFilterDropdownVisibleChange = this.onFilterVisibleChangeWithFocus(columnState);
          break;
        case FilterType.DateRange:
          columnProps.filterDropdown = this.dateRangeFilter(columnState, column.title);
          columnProps.onFilterDropdownVisibleChange = this.onFilterVisibleChangeWithFocus(columnState);
          break;
        case FilterType.BooleanCheckbox:
          columnProps.filterDropdown = this.booleanCheckboxFilter(columnState, column.title);
          columnProps.onFilterDropdownVisibleChange = this.onFilterVisibleChange(columnState);
          break;
        case FilterType.BooleanRadio:
          columnProps.filterDropdown = this.booleanRadioFilter(columnState, column.title);
          columnProps.onFilterDropdownVisibleChange = this.onFilterVisibleChange(columnState);
          break;
        case FilterType.DropdownSingle:
        case FilterType.DropdownMulti:
          if (!column.dropdownFilterOptions) {
            console.error(`No Dropdown Options Provided for Dropdown Filter. Column: ${column.title}`);
            break;
          }
          columnProps.filterDropdown = this.dropdownFilter(columnState, column);
          columnProps.onFilterDropdownVisibleChange = this.onFilterVisibleChange(columnState);
          break;
        default:
          columnFilterValid = false;
          break;
      }

      if (columnFilterValid) {
        columnProps.filterDropdownVisible = columnState.filterVisible;
        columnProps.filterIcon = this.filterIcon(columnState);
      }

      const render = columnProps.render;
      columnProps.render = render
        ? (value: any, record: T, index: number) =>
            render(column.renderDataTransform ? column.renderDataTransform(value, record) : value, record, index)
        : column.renderDataTransform
        ? column.renderDataTransform
        : undefined;

      if (this.state.sorter) {
        if (columnProps.sorter === true && !this.props.serverSide) {
          columnProps.sorter = (a: T, b: T) => {
            return columnProps.dataIndex
              ? [(a as any)[columnProps.dataIndex as any], (b as any)[columnProps.dataIndex as any]].sort()[0] ===
                (a as any)[columnProps.dataIndex as any]
                ? 1
                : -1
              : 0;
            // a[dataIndex].localeCompare(b[dataIndex]);
          };
        }
        columnProps.sortOrder = this.state.sorter.columnKey
          ? this.state.sorter.columnKey === columnProps.dataIndex && this.state.sorter.order
          : columnProps.sortOrder;
      }

      (columnProps.children || []).forEach((childCol: ColumnProps<T>) => {
        childCol.sortOrder = this.state.sorter.columnKey === childCol.dataIndex && this.state.sorter.order;
      });

      if (column.editOptions && column.editOptions.editable) {
        columnProps.onCell = (record: T) => ({
          record,
          inputType: column.editOptions ? column.editOptions.type : '',
          inputSize: compact ? 'small' : undefined,
          dataIndex: column.dataIndex,
          title: column.title as any,
          editing: this.props.editOptions && this.props.editOptions.fullTableEdit ? true : this.isEditing(record),
          inputStyle: column.editOptions ? column.editOptions.inputStyle : {},
          inputClass: column.editOptions ? column.editOptions.inputClass : '',
          decimalPrecision: column.editOptions ? column.editOptions.decimalPrecision : undefined,
          placeholder: column.editOptions ? column.editOptions.placeholder : undefined,
          customEditor: column.editOptions ? column.editOptions.customEditor : null,
          additional: column.editOptions ? column.editOptions.additional : null,
          disabled: column.editOptions ? column.editOptions.disabled : false,
          onChange: (column.editOptions ? column.editOptions.onChange : null) as any,
          onEnter: this.save,
          onEscape: this.cancel,
          renderDataTransform: column.renderDataTransform,
        });
      }
      if (!columnProps.key) {
        columnProps.key = Array.isArray(columnProps.dataIndex)
          ? columnProps.dataIndex.join()
          : columnProps.dataIndex?.toString();
      }

      return columnProps;
    });

    const { pagination, filters, sorter } = this.state;

    const classes = ['datatable'];
    if (this.props.wrapperClassName) {
      classes.push(this.props.wrapperClassName);
    }
    if (this.props.styleOptions) {
      if (this.props.styleOptions.bordered) {
        classes.push('datatable-bordered');
      }
      if (this.props.styleOptions.alternatingRowHighlight) {
        classes.push('datatable-alternating-row');
      }
      if (this.props.styleOptions.compact) {
        classes.push('datatable-compact');
      }
    }
    if (this.props.editOptions && this.props.editOptions.rowClickForEdit) {
      classes.push('cursor-hand');
    }
    if (this.props.onRowClick) {
      classes.push('cursor-hand');
    }

    return (
      <div className={classes.join(' ')}>
        {(this.props.title || this.props.buttonBar || this.props.globalSearch) && (
          <div className="datatable-header" style={{ display: 'flex' }}>
            {this.props.title ? <Typography.Title level={3}>{this.props.title}</Typography.Title> : null}
            {this.getGlobalSearch()}
            {this.props.buttonBar && (
              <div className="dataTable-button-bar">
                {this.props.buttonBar.map((button) => {
                  // eslint-disable-next-line
                  const { dataTableButtonType, export: exp, to, ...buttonProps } = button as any;
                  switch (dataTableButtonType) {
                    case DataTableButtonType.Export:
                      button.onClick = () =>
                        (button as ExportButton).export(
                          this.getTableStateDTO(pagination, filters, sorter, this.state.globalFilter)
                        );
                      break;
                    case DataTableButtonType.Reset:
                      button.onClick = () => this.resetTable();
                      break;
                    case DataTableButtonType.Link:
                      button.onClick = () => {
                        HistoryUtil.push(to);
                      };
                      break;
                    default:
                      break;
                  }

                  return (
                    <Button {...buttonProps} key={button.label}>
                      {button.label}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        )}
        <Table {...(tableProps as TableProps<any>)} />
      </div>
    );
  }

  private addColumnState(column: DataTableColumnProps<T>) {
    const stateColumnsCopy = this.state.columnStates.map((c) => c);
    stateColumnsCopy.push(
      TableColumnState.create({
        columnProp: column.dataIndex || column.key,
        columnType: column.columnType,
        initialSearchValue: column.initialFilterValue,
        searchText:
          column.initialFilterValue !== null && column.initialFilterValue !== undefined
            ? (column.initialFilterValue as string)
            : '',
        filtered: column.initialFilterValue !== null && column.initialFilterValue !== undefined,
        renderDataTransform: column.renderDataTransform,
      })
    );
    this.setState({ columnStates: stateColumnsCopy });
  }

  private getRowKey(record: T, index: number) {
    return typeof this.props.tableProps.rowKey === 'function'
      ? this.props.tableProps.rowKey(record, index)
      : `${this.getObjectValueByPath(record, `${this.props.tableProps.rowKey || 'id'}`)}`;
  }

  private getObjectValueByPath = (o: any, s: string) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  };

  private getSelectColumn() {
    // Update selectedrowIds, removing lines can cause odd behavior of title checkbox, make sure all ids are still in the dataset
    this.selectedRowIds = this.selectedRowIds.filter((key) =>
      (this.state.filteredData || this.state.data).some((a, i) => this.getRowKey(a, i) === key)
    );

    const checkedCount = this.selectedRowIds.length;
    const dataCount = (this.state.filteredData || this.state.data).length;

    return {
      columnProps: {
        title: (
          <Checkbox
            checked={checkedCount > 0 && checkedCount === dataCount}
            indeterminate={checkedCount !== 0 && checkedCount !== dataCount}
            onChange={(e) => {
              let newSelected = this.selectedRowIds;
              if (!e.target.checked) {
                newSelected = [];
              } else {
                newSelected = (this.state.filteredData || this.state.data).map((a, i) => this.getRowKey(a, i));
              }
              this.selectedRowIds = newSelected;
              this.forceUpdate();
            }}
          />
        ),
        dataIndex: 'selectColumn_box',
        render: (val: any, record: T, i: number) => {
          const rowKey = this.getRowKey(record, i);
          const isSelectable = this.props.selectVisibility ? this.props.selectVisibility(record) : true;
          if (isSelectable) {
            return (
              <Checkbox
                checked={this.selectedRowIds.indexOf(rowKey) !== -1}
                onChange={(e) => {
                  const newSelected = this.selectedRowIds.filter((a) => a !== rowKey);
                  if (e.target.checked) {
                    newSelected.push(rowKey);
                  }
                  this.selectedRowIds = newSelected;
                  this.forceUpdate();
                }}
              />
            );
          } else {
            return <></>;
          }
        },
        width: 20,
      },
    } as DataTableColumnProps<T>;
  }

  private getStateSavingTableKey() {
    if (!this.props.stateSaving || !this.props.stateSaving.tableUniqueKey) {
      console.log('State saving unique table key not set!');
      return '';
    }

    return 'DataTable_' + this.props.stateSaving.tableUniqueKey;
  }

  private getGlobalSearch = () => {
    if (!this.props.globalSearch) {
      return null;
    }

    return (
      <Tooltip title="Comma-separated list of terms">
        <Input.Search
          placeholder={this.props.globalSearchPlaceholder}
          className="generic-filter"
          enterButton={<Button icon={<SearchOutlined />} ghost />}
          size="middle"
          value={this.state.globalFilter || ''}
          onChange={(e: any) => this.setState({ globalFilter: e.target.value })}
          onPressEnter={() => this.onSearch()}
          onSearch={() => this.onSearch()}
        />
      </Tooltip>
    );
  };

  private callFetchData(pagination: any, filters: any, sorter: any, globalFilter: string | null) {
    if (this.props.fetchData && typeof this.props.fetchData === 'function') {
      const requestState = this.getTableStateDTO(pagination, filters, sorter, globalFilter);

      this.props.fetchData(
        requestState,
        () => requestState.echo === this.echo,
        (response) => {
          const pager = { ...pagination };
          pager.total = response.filteredCount;
          pager.dataTableUnfilteredTotal = response.totalCount;
          if (pager.pageSize * (pager.current - 1) > response.filteredCount) {
            pager.current = 0;
          }
          this.setState({
            data: response.results || [],
            pagination: pager,
            sumResult: response.sumResult,
          });
        }
      );
    } else if (this.props.fetchData && this.props.fetchData.fetch) {
      const requestState = this.getTableStateDTO(pagination, filters, sorter, globalFilter);
      this.setState({ loading: true });

      this.props.fetchData
        .fetch(requestState)
        .then((response) => {
          if (requestState.echo !== this.echo) {
            return;
          }

          const pager = { ...pagination };
          pager.total = response.filteredCount;
          pager.dataTableUnfilteredTotal = response.totalCount;
          if (pager.pageSize * (pager.current - 1) > response.filteredCount) {
            pager.current = 0;
          }
          this.setState(
            {
              data: response.results || [],
              loading: false,
              pagination: pager,
              sumResult: response.sumResult,
            },
            () => {
              if (this.props.fetchData && typeof this.props.fetchData !== 'function' && this.props.fetchData.success) {
                this.props.fetchData.success(response.results || []);
              }
            }
          );
        })
        .catch((error: any) => {
          this.setState({ loading: false });
          if (this.props.fetchData && typeof this.props.fetchData !== 'function' && this.props.fetchData.fail) {
            this.props.fetchData.fail(error);
          } else {
            notification.error({
              message:
                this.props.fetchData &&
                typeof this.props.fetchData !== 'function' &&
                this.props.fetchData.failureMessage
                  ? this.props.fetchData?.failureMessage
                  : 'Failed to retrieve data.',
            });
          }
        });
    }
  }

  private handleTableChange(pagination: any, filters: any, sorter: any) {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;

    this.setState({
      pagination: pager,
      sorter: sorter,
    });

    this.callFetchData(pager, this.state.filters, sorter, this.state.globalFilter);

    if (this.props.stateSaving && this.props.stateSaving.enabled) {
      if (this.state.sorter.columnKey !== sorter.columnKey || this.state.sorter.order !== sorter.order) {
        this.saveTableState(
          {
            columnKey: sorter.columnKey,
            field: sorter.field,
            order: sorter.order,
          },
          this.state.filters,
          this.state.columnStates,
          this.state.globalFilter
        );
      }
    }
  }

  private saveTableState(sorter: any, filters: any, columnStates: any, globalFilter: string | null) {
    if (this.props.stateSaving && this.props.stateSaving.enabled) {
      const data = JSON.stringify({
        sorter,
        filters,
        columnStates,
        globalFilter,
      });

      this.props.stateSaving.perSession
        ? sessionStorage.setItem(this.getStateSavingTableKey(), data)
        : localStorage.setItem(this.getStateSavingTableKey(), data);
    }
  }

  private getTableStateDTO(pagination: any, filters: any, sorter: any, globalFilter: string | null): TableRequestDTO {
    this.echo += 1;
    return {
      pageLength: pagination.pageSize || pagination.defaultPageSize,
      page: (pagination.current || pagination.defaultCurrent) - 1,
      sortField: sorter.field || null,
      sortOrder: sorter.order || null,
      echo: this.echo,
      filters: filters,
      globalFilter,
    };
  }

  private textFilter(stateObject: TableColumnState, columnTitle: any): any {
    if (!stateObject) {
      return <React.Fragment />;
    }

    return (
      <div className="text-filter-dropdown">
        <Input
          ref={(ele: any) => (this.searchInputs[stateObject.columnProp] = ele)}
          placeholder={`Search ${columnTitle}`} // TODO: columnTitle could be react element
          value={stateObject.searchText || ''}
          onChange={(e: any) => this.onInputChange(e, stateObject)}
          onPressEnter={() => this.onSearch(stateObject)}
          allowClear={true}
          width={130}
        />
        <Button type="primary" onClick={() => this.onSearch(stateObject)}>
          Search
        </Button>
      </div>
    );
  }

  private dateFilter(stateObject: TableColumnState, columnTitle: any): any {
    let div: any;
    const searchButtonId = 'button_' + stateObject.columnProp;
    return (
      <div ref={(ele: any) => (div = ele)} className="date-filter-dropdown">
        <DatePicker
          ref={(ele: any) => (this.searchInputs[stateObject.columnProp] = ele)}
          placeholder={`Search ${columnTitle}`} // TODO: columnTitle could be react element
          // value={stateObject.searchText}
          onChange={(date, dateString) => this.onDateInputChange(date, dateString, stateObject, searchButtonId)}
          getPopupContainer={() => div}
          format="M/D/YYYY"
          allowClear={true}
        />
        <Button id={searchButtonId} type="primary" onClick={() => this.onSearch(stateObject)}>
          Search
        </Button>
      </div>
    );
  }

  private dateRangeFilter(
    stateObject: TableColumnState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    columnTitle: any
  ): any {
    let div: any;
    const searchButtonId = 'button_' + stateObject.columnProp;
    return (
      <div ref={(ele: any) => (div = ele)} className="daterange-filter-dropdown">
        <RangePicker
          ref={(ele: any) => (this.searchInputs[stateObject.columnProp] = ele)}
          onChange={(date, dateString) =>
            this.onDateRangeInputChange(date, dateString, stateObject, document.getElementById(searchButtonId))
          }
          onOpenChange={(status) => this.onDateRangePickerOpenChange(status, stateObject)}
          getPopupContainer={() => div}
          format="M/D/YYYY"
          allowClear={true}
        />
        <Button id={searchButtonId} type="primary" onClick={() => this.onSearch(stateObject)}>
          Search
        </Button>
      </div>
    );
  }

  private booleanCheckboxFilter(
    stateObject: TableColumnState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    columnTitle: any
  ): any {
    let searchButton: any;
    return (
      <div className="boolean-checkbox-filter-dropdown">
        <Checkbox
          ref={(ele) => (this.searchInputs[stateObject.columnProp] = ele)}
          onChange={(e) => this.onInputChange(e, stateObject, searchButton, (stateCopy) => this.onSearch(stateCopy))}
          checked={!!stateObject.searchText}
        />
      </div>
    );
  }

  private booleanRadioFilter(
    stateObject: TableColumnState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    columnTitle: any
  ): any {
    const searchButtonId = 'button_' + stateObject.columnProp;
    return (
      <div className="boolean-radio-filter-dropdown">
        {/* TODO: Fix this ref because Ant broke it... */}
        <RadioGroup
          ref={(ele) => (this.searchInputs[stateObject.columnProp] = ele)}
          onChange={(e) => this.onInputChange(e, stateObject, document.getElementById(searchButtonId))}
          value={stateObject.searchText ? stateObject.searchText : ''}
        >
          <Radio value="true">Yes</Radio>
          <Radio value="false">No</Radio>
          <Radio value="">All</Radio>
        </RadioGroup>
        <Button id={searchButtonId} type="primary" onClick={() => this.onSearch(stateObject)}>
          Search
        </Button>
      </div>
    );
  }

  private dropdownFilter(stateObject: TableColumnState, column: any): any {
    // DataTableColumnProps
    const options: { text: string; value: string }[] = column.dropdownFilterOptions || []; // text, value

    return (
      <div style={{ padding: 5, minWidth: 90 }}>
        {column.filterType === FilterType.DropdownMulti ? (
          <CheckboxGroup
            onChange={(opts) => this.onInputChange({ target: { value: opts.join(','), type: 'text' } }, stateObject)}
            value={(stateObject.searchText || '').split(',')}
            style={{ textAlign: 'left' }}
          >
            {options.map((o: any, i: number) => (
              <React.Fragment key={`${column.title}_dpdn_${i}`}>
                <Checkbox ref={(ele: any) => (this.searchInputs[stateObject.columnProp] = ele)} value={o.value}>
                  {o.text}
                </Checkbox>
                {i < options.length - 1 ? <br /> : null}
              </React.Fragment>
            ))}
          </CheckboxGroup>
        ) : (
          // TODO: Fix this ref because Ant broke it...
          <RadioGroup
            ref={(ele: any) => (this.searchInputs[stateObject.columnProp] = ele)}
            onChange={(e) => this.onInputChange(e, stateObject)}
            value={stateObject.searchText || undefined}
            style={{ textAlign: 'left' }}
          >
            {options.map((o: any, i: number) => (
              <React.Fragment key={`${column.title}_dpdn_${i}`}>
                <Radio value={o.value}>{o.text}</Radio>
                {i < options.length - 1 ? <br /> : null}
              </React.Fragment>
            ))}
          </RadioGroup>
        )}

        <hr />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <a
            onClick={() =>
              this.onInputChange({ target: { value: '', type: 'text' } }, stateObject, null, (s) => this.onSearch(s))
            }
          >
            Reset
          </a>
          <Button size="small" type="primary" onClick={() => this.onSearch(stateObject)}>
            Okay
          </Button>
        </div>
      </div>
    );
  }

  private filterIcon(stateObject: TableColumnState) {
    return stateObject.filtered ? (
      <FilterFilled style={{ color: '#108ee9' }} />
    ) : (
      <FilterOutlined style={{ color: '#aaa' }} />
    );
  }

  private onFilterVisibleChangeWithFocus(stateObject: TableColumnState): any {
    return (visible: boolean) => {
      const stateCopy = { ...stateObject };
      stateCopy.filterVisible = visible;

      this.setState({ columnStates: this.getUpdatedColumnStateList(stateCopy) }, () => {
        // Use setTimeout so that the element ref exists after first rendering
        setTimeout(() => {
          if (this.searchInputs[stateCopy.columnProp]) {
            this.searchInputs[stateCopy.columnProp].focus();
          }
        }, 100); //Changed from 0 to 100 to prevent focus at top of screen
      });
    };
  }

  private onFilterVisibleChange(stateObject: TableColumnState): any {
    return (visible: boolean) => {
      const stateCopy = { ...stateObject };
      stateCopy.filterVisible = visible;

      this.setState({
        columnStates: this.getUpdatedColumnStateList(stateCopy),
      });
    };
  }

  private onDateRangePickerOpenChange(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    open: boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stateObject: TableColumnState
  ): any {
    // return (status: boolean) => {
    // let picker = this.searchInputs[stateObject.columnProp];
    // let currentText = (picker.value[0] as moment.Moment).format(picker.format) +
    //     ' - ' + (picker.value[1] as moment.Moment).format(picker.format);
    //console.log(`date range picker ${status ? 'opened' : 'closed'}`);
    // if (currentText !== stateObject.searchText) {
    //     console.log('date range filter changed');
    // }
    // var stateCopy = { ...stateObject };
    // stateCopy.filterVisible = visible;
    // this.setState(
    //     { columnStates: this.getUpdatedColumnStateList(stateCopy) },
    //     () => this.searchInputs[stateCopy.columnProp] && this.searchInputs[stateCopy.columnProp].focus());
    // };
  }

  private onInputChange = (
    e: any,
    stateObject: TableColumnState,
    searchButton?: any,
    callback?: (columnState: TableColumnState) => any
  ) => {
    const stateCopy = { ...stateObject };
    stateCopy.searchText = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState({ columnStates: this.getUpdatedColumnStateList(stateCopy) }, () => {
      if (searchButton) {
        searchButton.focus();
      }
      if (callback) {
        callback(stateCopy);
      }
    });
  };

  private onDateInputChange = (
    date: moment.Moment | null,
    dateString: string,
    stateObject: TableColumnState,
    searchButtonId: string
  ) => {
    const stateCopy = { ...stateObject };
    stateCopy.searchText = dateString;

    this.setState({ columnStates: this.getUpdatedColumnStateList(stateCopy) }, () => {
      const e = document.getElementById(searchButtonId);
      if (e) {
        e.focus();
      }
    });
  };

  private onDateRangeInputChange = (
    dates: any, // RangeValue<moment.Moment>,
    dateStrings: [string, string],
    stateObject: TableColumnState,
    searchButton: any
  ) => {
    const stateCopy = { ...stateObject };
    stateCopy.searchText = dateStrings[0] && dateStrings[1] ? `${dateStrings[0]} - ${dateStrings[1]}` : null;

    this.setState({ columnStates: this.getUpdatedColumnStateList(stateCopy) }, () => {
      searchButton.focus();
    });
  };

  private onSearch = (stateObject?: TableColumnState) => {
    let updatedColumnsStates: TableColumnState[] = [];
    const filters: TableRequestFilterDTO[] = this.state.filters.map((f) => f);

    if (stateObject) {
      const stateCopy = { ...stateObject };
      const { searchText } = stateCopy;
      // const reg = new RegExp(searchText ? searchText : '', 'gi');
      stateCopy.filterVisible = false;
      stateCopy.filtered = !!searchText;

      const filterObj = filters.filter((value) => value.columnProp === stateCopy.columnProp);
      if (filterObj.length > 0) {
        filterObj[0].filter = searchText;
      } else {
        filters.push({ columnProp: stateCopy.columnProp, filter: searchText });
      }

      updatedColumnsStates = this.getUpdatedColumnStateList(stateCopy);
    } else {
      updatedColumnsStates = this.state.columnStates;
    }

    if (this.props.serverSide) {
      this.setState(
        {
          filters,
          pagination: { ...this.state.pagination, current: 1 },
          columnStates: updatedColumnsStates,
        },
        () => this.callFetchData(this.state.pagination, filters, this.state.sorter, this.state.globalFilter)
      );
    } else {
      const data = (this.props.data || [])
        .map((item: T): any => {
          let match = true;
          filters.forEach((f) => {
            const reg = new RegExp(f.filter ? f.filter : '', 'gi');
            const columnState = updatedColumnsStates.filter((ucs) => ucs.columnProp === f.columnProp)[0];
            const propVal = columnState.renderDataTransform
              ? columnState.renderDataTransform(item[columnState.columnProp], item)
              : item[columnState.columnProp];
            const propType = columnState.columnType || typeof propVal;
            switch (propType) {
              case ColumnType.Text:
                match = match && (propVal ? propVal.match(reg) : false);
                break;
              case ColumnType.Date:
                if (f.filter && moment(propVal).isValid() && moment(f.filter).isValid()) {
                  match = match && moment(f.filter).isSame(moment(propVal));
                }
                break;
              case 'string':
                match = match && propVal.match(reg);
                break;
              case ColumnType.Number:
              case 'number':
                // Use starts with for numbers?
                match = match && propVal.toString().match(reg);
                break;
              case ColumnType.Boolean:
              case 'boolean':
                match = match && (!f.filter || propVal.toString() === f.filter);
                break;
              case 'object':
                if (moment.isMoment(propVal) && f.filter) {
                  const dateSplit = f.filter.split(' - ');
                  if (dateSplit.length === 2) {
                    match = match && propVal.isBetween(moment(dateSplit[0]), moment(dateSplit[1]), 'days', '[]');
                  }
                }
                break;
              default:
                break;
            }
          });

          let globalMatch = true;
          if (this.state.globalFilter) {
            this.state.globalFilter.split(',').forEach((f) => {
              f = f.trim();
              const reg = new RegExp(f ? f : '', 'gi');
              let rowMatch = false;
              for (const prop in item) {
                // eslint-disable-next-line no-prototype-builtins
                if ((item as any).hasOwnProperty(prop)) {
                  const propVal = item[prop];
                  switch (typeof propVal) {
                    case 'string':
                      rowMatch = rowMatch || !!propVal.match(reg);
                      break;
                    case 'number':
                      // Use starts with for numbers?
                      rowMatch = rowMatch || !!(propVal as number).toString().match(reg);
                      break;
                    case 'boolean':
                      rowMatch = rowMatch || !f || (propVal as boolean).toString() === f;
                      break;
                    case 'object':
                      if (moment.isMoment(propVal) && f) {
                        // Ignore
                      }
                      break;
                    default:
                      break;
                  }
                }
              }
              globalMatch = globalMatch && rowMatch;
            });
          }

          if (!match || !globalMatch) {
            return null;
          }
          return {
            ...item,
            // name: (
            //     <span>
            //         {part.name.split(reg).map((text, i) => (
            //             i > 0 ? [<span className="highlight">{match[0]}</span>, text] : text
            //         ))}
            //     </span>
            // ),
          };
        })
        .filter((item: any) => !!item);

      this.setState({
        filters,
        columnStates: updatedColumnsStates,
        filteredData: data,
        sumResult: this.getLocalSumResult(data),
      });
    }

    this.saveTableState(
      {
        columnKey: this.state.sorter.columnKey,
        field: this.state.sorter.field,
        order: this.state.sorter.order,
      },
      filters,
      updatedColumnsStates,
      this.state.globalFilter
    );
  };

  private getColumnState(customColumnProps: DataTableColumnProps<T>): TableColumnState {
    return (
      this.state.columnStates.find((cs) => cs.columnProp === (customColumnProps.dataIndex || customColumnProps.key)) ||
      TableColumnState.create({
        columnProp: customColumnProps.dataIndex || customColumnProps.key,
        columnType: customColumnProps.columnType,
        initialSearchValue: customColumnProps.initialFilterValue || '',
        searchText:
          customColumnProps.initialFilterValue !== null && customColumnProps.initialFilterValue !== undefined
            ? (customColumnProps.initialFilterValue as string)
            : '',
        filtered: customColumnProps.initialFilterValue !== null && customColumnProps.initialFilterValue !== undefined,
        renderDataTransform: customColumnProps.renderDataTransform,
      })
    );
  }

  private getUpdatedColumnStateList(modifiedColumnState: TableColumnState): TableColumnState[] {
    return this.state.columnStates.map((cs) =>
      cs.columnProp === modifiedColumnState.columnProp ? modifiedColumnState : cs
    );
  }

  private isEditing = (record: T) => {
    return record === this.state.editingRecord;
  };

  private edit = (record: T, form?: FormInstance, index?: number, saveOnClickOutside?: boolean) => {
    if (this.props.editOptions && !this.props.editOptions.fullTableEdit && index === this.state.editingIndex) {
      return;
    }

    const editRow = () => {
      if (form) {
        this.editingRowForm = form;
        this.editingRowForm.setFieldsValue(record);
      }

      this.setState({ editingRecord: record, editingIndex: index });
    };

    if (saveOnClickOutside) {
      this.saveOnClickOutside(form || this.editingRowForm, record || ({} as T), index || -1);
    }

    if (form) {
      this.save(form, record || ({} as T), index || -1, (saved) => {
        if (!saved) {
          return;
        }

        editRow();
      });
    } else {
      editRow();
    }
  };

  private save = (form: FormInstance, record: T, index: number, callback?: (saved: boolean) => void) => {
    form
      .validateFields()
      .then((row) => {
        let isChanged = false;

        for (const property in row) {
          if (moment.isMoment(row[property])) {
            if (property.toLowerCase().indexOf('time') > -1 && property.toLowerCase().indexOf('date') === -1) {
              row[property] = (row[property] as moment.Moment).format('HH:mm:ss');
            } else {
              row[property] = (row[property] as moment.Moment).format();
            }
          }
        }

        for (const property in row) {
          if (row[property] !== record[property]) {
            isChanged = true;
          }
        }

        const finishSave = (success: boolean, updatedRows?: T[]) => {
          this.setState(
            {
              data: success && updatedRows ? updatedRows : this.state.data,
              editingRecord: null,
              editingIndex: null,
            },
            () => {
              this.refresh();
              if (callback) {
                callback(success);
              }
            }
          );
        };

        if (isChanged) {
          if (this.props.editOptions && this.props.editOptions.saveRow) {
            this.props.editOptions.saveRow(record, row as T, (success, updatedRows) => {
              if (!success) {
                return;
              }

              finishSave(success, updatedRows);
            });
          } else {
            finishSave(true);
          }
        }

        // const newData = [...this.state.timesheetDetails];
        // const index = newData.findIndex(item => id === item.id);
        // if (index > -1) {
        //     const item = newData[index];
        //     newData.splice(index, 1, {
        //         ...item,
        //         ...row,
        //     });
        //    this.setState({ timesheetDetails: newData, editingId: null }, () => this._timesheetEntryDataTable.refresh());
        // } else {
        //     newData.push(row);
        //     this.setState({ timesheetDetails: newData, editingId: null }, () => this._timesheetEntryDataTable.refresh());
        // }
      })
      .catch(() => {
        return;
      });
  };

  private cancel = () => {
    this.setState({ editingRecord: null, editingIndex: null }, () =>
      this.props.editOptions ? (this.props.editOptions.cancelEdit ? this.props.editOptions.cancelEdit() : null) : null
    );
  };

  private getLocalSumResult = (data: T[]) => {
    if (!this.props.showSum) {
      return null;
    }

    const columns = this.props.columns;

    if (data.length <= 1) {
      return null;
    }

    // TODO: dataIndex is probably an array...
    const result: any = {};
    columns.forEach((c) => {
      result[(c.dataIndex as string) || ''] = undefined;
    });

    data.forEach((d) => {
      columns.forEach((c) => {
        const dataIndexSplit = ((c.dataIndex as string) || '').split('.');
        let temp = d;
        dataIndexSplit.forEach((dis) => (temp = temp[dis]));
        const val = temp;
        result[(c.dataIndex as string) || ''] = (result[(c.dataIndex as string) || ''] || 0) + (val || 0);
      });
    });

    return result || null;
  };
}

export default DataTable;
