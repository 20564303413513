import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import React, { useEffect, useState } from 'react';
import FeatureFlagDTO from '../../models/FeatureFlagDTO';
import FeatureFlagGroupDTO from '../../models/FeatureFlagGroupDTO';
import FeatureFlagApiService from '../../api/FeatureFlagApiService';
import '../../extensions/ArrayExtensions';
import { Card, Col, Row, Space } from 'antd';

interface FeatureFlagEditorProps {
  featureFlagGroupName?: string;
  baseFeatureFlag: FeatureFlagDTO[] | null;
  readOnly?: boolean;
  onChange?: (baseFeatureFlags: FeatureFlagDTO[] | null) => void;
}
const FeatureFlagEditor = (props: FeatureFlagEditorProps) => {
  useEffect(() => {
    getFeatureFlag();
  }, props.baseFeatureFlag ?? []);

  const [featureFlagGroupDTO, setFeatureFlagDTO] = useState<FeatureFlagGroupDTO[]>();

  const getFeatureFlag = () => {
    FeatureFlagApiService.getFeatureFlagByGroup(props.featureFlagGroupName ?? '').then((res) => {
      setFeatureFlagDTO(res);
    });
  };

  const changeFeatureFlag = (e: CheckboxChangeEvent, featureFlagId: number) => {
    const resultFeatureFlag: FeatureFlagDTO[] = [];
    resultFeatureFlag.pushAll(props.baseFeatureFlag ?? []);

    if (e.target.checked) {
      if ((props.baseFeatureFlag?.findIndex((x) => x.id == featureFlagId) ?? -1) == -1) {
        resultFeatureFlag?.push(FeatureFlagDTO.create({ id: featureFlagId }));
      }
    } else {
      resultFeatureFlag?.remove(props.baseFeatureFlag?.find((x) => x.id == featureFlagId));
    }

    if (props.onChange) {
      props.onChange(resultFeatureFlag);
    }
  };

  const isChecked = (featureFlagId: number) => {
    return (props.baseFeatureFlag?.findIndex((x) => x.id == featureFlagId) ?? -1) > -1;
  };

  const renderFeatureFlagGroups = () => {
    return featureFlagGroupDTO?.map((x) => (
        <Col xs={48} key={x.name}>
        <Card title={x.name} key={x.name} style={{marginBottom: '10px'}}>
          <Space direction="vertical" key={x.name}>
            <Row gutter={[16, 16]}>{renderFeatureFlagsByGroup(x)}</Row>
            <strong>{'Default Value(s)'}</strong>
            <Row gutter={[16, 16]}>{renderDefaultValueFeatureFlagsByGroup(x)}</Row>
          </Space>
        </Card>
        </Col>
    ));
  };

  const renderFeatureFlagsByGroup = (featureFlagGroup: FeatureFlagGroupDTO) => {
    return featureFlagGroup.featureFlags?.map((x) => {
      if (!x.isDefaultValue) {
        return renderCheckBox(x);
      }
    });
  };

  const renderDefaultValueFeatureFlagsByGroup = (featureFlagGroup: FeatureFlagGroupDTO) => {
    return featureFlagGroup.featureFlags?.map((x) => {
      if (x.isDefaultValue) {
        return renderCheckBox(x);
      }
    });
  };

  const renderCheckBox = (featureFlag: FeatureFlagDTO) => {
    return (
      <Col xs={48} key={featureFlag.name}>
        <Checkbox
          disabled={props.readOnly}
          checked={isChecked(featureFlag.id)}
          onChange={(e) => {
            changeFeatureFlag(e, featureFlag.id);
          }}
        >
          {featureFlag.name ?? ''}
        </Checkbox>
      </Col>
    );
  };

  return < Col xs={48}>{renderFeatureFlagGroups()}</Col>;
};

export default FeatureFlagEditor;
