import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import Routes from '../../consts/Routes';
import useUrlQuery from '../../hooks/useUrlQuery';
import HistoryUtil from '../../utils/HistoryUtil';
import LoginLayout from './LoginLayout';
import * as queryString from 'query-string';
import ManageMFA from '../../components/account/MFA/ManageMFA';

const FormItem = Form.Item;

const devLoginKey = 'lastDevLogin';

interface LoginPageProps {
  errors?: string;
  returnUrl?: string;
  isLockOut?: string;
  mobileApp?: string;
}

const LoginPage = () => {
  const [error, setError] = useState<any>(null);
  const [form] = useForm();
  const [shouldRequestPassword, setShouldRequestPassword] = useState(false);
  const [showMFA, setShowMFA] = useState(false);
  const [showMFASetup, setShowMFASetup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>();
  const [loading, setLoading] = useState(false);

  const [routeQuery] = useUrlQuery<LoginPageProps>();
  const authContext = useContext(AuthenticationContext);

  const initVals = { userName: DEBUG ? localStorage.getItem(devLoginKey) ?? undefined : undefined };

  useEffect(() => {
    if (routeQuery.errors) {
      setError({ message: routeQuery.errors });
    }
  }, [routeQuery.errors]);

  const handleSubmit = async (model: any) => {
    if (DEBUG) {
      localStorage.setItem(devLoginKey, model.userName);
    }

    model.isMobileApp = !!routeQuery?.mobileApp;

    if (model.userName && /@claim-doc.com\s*$/.test(model.userName)) {
      if (!routeQuery.mobileApp) {
        redirectForExternalLogin(model.userName);
      } else {
        Modal.error({ title: 'ClaimDOC Employees Not Permitted' });
      }
    } else if (shouldRequestPassword) {
      setLoading(true);
      try {
        const res = await authContext.login(model);
        if (res.requiresTwoFactor && !res.requiresMfaSetup) {
          setShowMFA(true);
        } else {
          if (!res.succeeded) {
            const newError = res.errors && res.errors.length > 0 ? { message: res.errors.join('\n') } : {};
            setError(newError);
          } else if (res.requiresMfaSetup) {
            setShowMFASetup(true);
            setPhoneNumber(res.phoneNumber);
          } else {
            let target = routeQuery.returnUrl;
            if (routeQuery.mobileApp) {
              const addons = queryString.stringify({
                portalUserId: res.id,
                memberId: res.claimDocMemberId,
                username: res.username,
                firstName: res.firstName,
                lastName: res.lastName,
              });
              target += (target?.includes('?') ?? true ? '&' : '?') + addons;
            }

            HistoryUtil.replace(target ?? Routes.HOME_ROUTE);
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      setShouldRequestPassword(true);
    }
  };

  const redirectForExternalLogin = (userName: string) => {
    if (typeof window !== undefined) {
      let url = 'api/Account/ExternalLogin';
      let prefix = url.indexOf('?') > 0 ? '&' : '?';

      url += `${prefix}provider=${encodeURIComponent('AzureAD')}`;
      prefix = '&';
      url += `${prefix}login=${encodeURIComponent(userName)}`;

      if (routeQuery.returnUrl) {
        url += `${prefix}returnUrl=${encodeURIComponent(routeQuery.returnUrl)}`;
      }
      setLoading(true);
      window.location.href = url;
    }
  };

  const goToRegister = () => {
    HistoryUtil.push(Routes.generate(Routes.REGISTER));
  };

  let alert: JSX.Element | null = null;
  if (error) {
    let message = 'An error occurred while trying to log you in.';
    if (error?.message) {
      message = error?.message;
    }
    alert = (
      <Alert
        message="Error"
        description={message}
        type="error"
        showIcon={true}
        style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
      />
    );
  } else if (routeQuery.isLockOut) {
    alert = (
      <Alert
        message="Error"
        description="Account Locked"
        type="error"
        showIcon={true}
        style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
      />
    );
  }

  if (authContext?.user != null && (authContext?.user.isGuestAccount ?? false)) {
    return <Redirect to={Routes.GUEST_BASE} />;
  }

  return (
    <>
      <Modal
        visible={showMFASetup}
        onCancel={() => setShowMFASetup(false)}
        maskClosable={false}
        destroyOnClose
        footer={null}
      >
        <ManageMFA twoFactorEnabled={false} currentPhone={phoneNumber} reload={() => setShowMFASetup(false)} />
      </Modal>
      <LoginLayout cardTitle="Welcome to ClaimDOC!" pageTitle="Login" prompt="Please login or sign up below.">
        <Row justify="center">
          <Col xs={24} sm={18}>
            {alert}
            <Form form={form} layout="vertical" onFinish={handleSubmit} requiredMark={false} initialValues={initVals}>
              <FormItem
                name="userName"
                label="Email Address"
                rules={[{ required: true, message: 'Email Address is required' }]}
              >
                <Input disabled={showMFA} />
              </FormItem>
              {shouldRequestPassword ? (
                <FormItem
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: 'Password is required' }]}
                >
                  <Input.Password disabled={showMFA} autoFocus />
                </FormItem>
              ) : null}
              {showMFA ? (
                <FormItem name="mfaCode" label="Two-Factor Code" rules={[{ required: true, message: 'Required' }]}>
                  <Input autoFocus />
                </FormItem>
              ) : null}
              {shouldRequestPassword ? (
                <FormItem>
                  <Button type="primary" htmlType="submit" size="large" disabled={loading} shape="round" block>
                    {loading ? (
                      <span>
                        <LoadingOutlined /> Logging in...
                      </span>
                    ) : (
                      <span>Login</span>
                    )}
                  </Button>
                </FormItem>
              ) : (
                <FormItem>
                  <Button type="primary" htmlType="submit" size="large" disabled={loading} shape="round" block>
                    {loading ? (
                      <span>
                        <LoadingOutlined /> Loading...
                      </span>
                    ) : (
                      <span>Next</span>
                    )}
                  </Button>
                </FormItem>
              )}
              <div style={{ textAlign: 'center', marginBottom: 0 }}>
                <Link to={Routes.REQUEST_RESET_PASSWORD}>Forgot Password?</Link>
              </div>
              <div style={{ textAlign: 'center', marginBottom: 0 }}>
                <Link to={Routes.RESEND_CONFIRMATION_EMAIL}>Resend Confirmation Email</Link>
              </div>
            </Form>
            <Divider />
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
              Do you need an account?
            </Typography.Title>
            <Form>
              <FormItem>
                <Button
                  onClick={goToRegister}
                  htmlType="button"
                  size="large"
                  disabled={loading}
                  shape="round"
                  block
                  ghost
                >
                  Sign Up
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </LoginLayout>
    </>
  );
};

export default LoginPage;
