// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template.
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import ResultDTO from '../models/ResultDTO';
import MemberCardDTO from '../models/MemberCardDTO';
import LookupTableDTO from '../models/LookupTableDTO';

export class MemberCardApiService extends BaseApi {
  // post: api/membercards/save
  public saveMemberCard(request: FormData): Promise<ResultDTO> {
    let url = `api/membercards/save`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(request, 'post', url, false, false);
  }

  // delete: api/membercards/DeleteMemberCard?id=${id}
  public deleteMemberCard(id: number): Promise<ResultDTO> {
    let url = `api/membercards/DeleteMemberCard`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (id != null) {
      url += `${prefix}id=${id}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, ResultDTO>(null, 'delete', url, true, false);
  }

  // post: api/membercards/${id}/${encodeURIComponent(filename)}/${back}
  public cardImage(id: number, filename: string, back: boolean): Promise<void> {
    let url = `api/membercards/${id}/${encodeURIComponent(filename)}/${back}`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, void>(null, 'post', url, true, true);
  }

  // get: api/membercards/GetMemberCardsForUser?id=${id}
  public getMemberCardsForUser(id?: number | null): Promise<MemberCardDTO[]> {
    let url = `api/membercards/GetMemberCardsForUser`;
    let prefix = url.indexOf('?') > 0 ? '&' : '?';
    if (id != null) {
      url += `${prefix}id=${id}`;
      prefix = '&';
    }

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, MemberCardDTO[]>(null, 'get', url, true, false);
  }

  // get: api/membercards/MemberCardTypes
  public getMemberCardTypes(): Promise<LookupTableDTO[]> {
    let url = `api/membercards/MemberCardTypes`;

    url = url.replace(/null|undefined/gi, '');
    return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false);
  }
}
var service = new MemberCardApiService();
export default service;
