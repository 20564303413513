// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import BalanceBillQueryDTO from '../models/BalanceBillQueryDTO';
import FamilyMemberDTO from '../models/FamilyMemberDTO';
import BalanceBillQueryResultDTO from '../models/BalanceBillQueryResultDTO';
import RelationDTO from '../models/RelationDTO';
import MemberDTO from '../models/MemberDTO';

export class BalanceBillApiService extends BaseApi {

    // get: api/balancebill/FamilyMembers?memberClaimDocId=${encodeURIComponent(memberClaimDocId)}
    public familyMembers(memberClaimDocId?: string | null): Promise<FamilyMemberDTO[]> {
        let url = `api/balancebill/FamilyMembers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (memberClaimDocId != null) {
            url += `${prefix}memberClaimDocId=${encodeURIComponent(memberClaimDocId || '')}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, FamilyMemberDTO[]>(null, 'get', url, true, false);
    }

    // post: api/balancebill/SendAgreements?values=${encodeURIComponent(values)}
    public sendAgreements(values: string): Promise<boolean> {
        let url = `api/balancebill/SendAgreements`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (values != null) {
            url += `${prefix}values=${encodeURIComponent(values)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/balancebill/SubmitBalanceBill
    public submitBalanceBill(request: FormData): Promise<boolean> {
        let url = `api/balancebill/SubmitBalanceBill`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(request, 'post', url, false, false);
    }

    // post: api/balancebill/Query
    public query(queryDto: BalanceBillQueryDTO): Promise<BalanceBillQueryResultDTO> {
        let url = `api/balancebill/Query`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, BalanceBillQueryResultDTO>(queryDto, 'post', url, true, false);
    }

    // post: api/balancebill/document/${id}/${fileId}
    public documentDownload(id: number, fileId: number): Promise<void> {
        let url = `api/balancebill/document/${id}/${fileId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/balancebill/release/${encodeURIComponent(claimDocId)}/${fileInfoId}
    public agreementDownload(claimDocId: string, fileInfoId: number): Promise<void> {
        let url = `api/balancebill/release/${encodeURIComponent(claimDocId)}/${fileInfoId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // get: api/balancebill/getClients
    public getClients(): Promise<RelationDTO[]> {
        let url = `api/balancebill/getClients`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RelationDTO[]>(null, 'get', url, true, false);
    }

    // get: api/balancebill/getClientMembers?clientId=${clientId}&lastName=${encodeURIComponent(lastName)}
    public getClientMembers(clientId: number, lastName: string): Promise<MemberDTO[]> {
        let url = `api/balancebill/getClientMembers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (lastName != null) {
            url += `${prefix}lastName=${encodeURIComponent(lastName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, MemberDTO[]>(null, 'get', url, true, false);
    }
}
var service = new BalanceBillApiService();
export default service;
