import { Popconfirm, PopconfirmProps } from 'antd';
import React, { useState } from 'react';

interface ConitionalPopConfirmProps extends PopconfirmProps {
  condition: boolean | (() => boolean); // If popup should show or not
}

const ConditionalPopconfirm = (props: ConitionalPopConfirmProps) => {
  const [visible, setVisible] = useState(false);
  const { condition, ...confirmProps } = { ...props };

  const handleVisibleChange = (
    isVisible: boolean,
    e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement> | undefined
  ) => {
    // If hiding, just hide it & bail
    if (!isVisible) {
      setVisible(isVisible);
      if (props.onVisibleChange) {
        props.onVisibleChange(isVisible, e);
      }
      return;
    }

    // Determine if popup should auto confirm or not
    let conditionMet = false;
    if (typeof condition === 'function') {
      conditionMet = condition();
    } else {
      conditionMet = condition;
    }

    // If the condition is not met, show the confirm,
    // otherwise, just call the confirm
    if (!conditionMet) {
      if (props.onConfirm) {
        props.onConfirm();
      }
    } else {
      setVisible(isVisible);
    }

    if (props.onVisibleChange) {
      props.onVisibleChange(isVisible, e);
    }
  };

  // Call base props confirm after hiding
  const onConfirm = () => {
    setVisible(false);
    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  // Call base props cancel after hiding
  const cancel = () => {
    setVisible(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <Popconfirm
      {...confirmProps}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      onConfirm={onConfirm}
      onCancel={cancel}
    />
  );
};

export default ConditionalPopconfirm;
