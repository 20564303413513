import { Breadcrumb, Button, Col, Divider, Form, Input, notification, Row, Select, Space, Spin, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PermissionApiService from '../../../api/PermissionApiService';
import PinAccessApiService from '../../../api/PinAccessApiService';
import AccessRole from '../../../consts/AccessRole';
import Permission from '../../../consts/Permission';
import PermissionGroupNames from '../../../consts/PermissionGroupNames';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import useHasPermissions from '../../../hooks/userHasPermissions';
import LookupTableDTO from '../../../models/LookupTableDTO';
import PermissionGroupListDTO from '../../../models/PermissionGroupListDTO';
import PinAccessDTO from '../../../models/PinAccessDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import DatePicker2 from '../../shared/DatePicker2';
import PageTitle from '../../shared/PageTitle';

const FormItem = Form.Item;

interface GuestAccessDetailsPageProps {
  id: string;
}

const pinIdFromProps = (id: string) => {
  const parsed = parseInt(id);
  if (!isNaN(parsed)) {
    return parsed;
  }
  return null;
};

const GuestAccessDetailsPage = () => {
  const NEW = 'new';
  const routeProps = useParams<GuestAccessDetailsPageProps>();
  const [loading, setLoading] = useState(pinIdFromProps(routeProps.id) != null);
  const [pinId, setPinId] = useState<number | null>(pinIdFromProps(routeProps.id));
  const [knownClient, setKnownClient] = useState(true);
  const [dto, setDto] = useState<PinAccessDTO>(
    PinAccessDTO.create({ expirationDate: null })
  );
  const [changesPending, setChangesPending] = useState(dto.id == 0);
  const [form] = useForm();

  const [clients, setClients] = useState<LookupTableDTO[]>([]);
  const [parentPins, setParentPins] = useState<LookupTableDTO[]>([]);
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroupListDTO[]>([]);

  const individualPin = dto.userEmail != null;
  const parentExp = !dto.canEditExpiration;

  const hasPermission = useHasPermission();
  const hasPermissions = useHasPermissions();

  const hasPinAccess = hasPermissions([
    Permission.ADMIN_EMPLOYERPINACCESSUNLINKED_EDIT,
    Permission.ADMIN_EMPLOYERPINACCESSLINKED_EDIT,
  ]);

  const hasUnlinkedPinAccess = hasPermission(Permission.ADMIN_EMPLOYERPINACCESSUNLINKED_EDIT);
  const hasLinkedPinAccess = hasPermission(Permission.ADMIN_EMPLOYERPINACCESSLINKED_EDIT);

  useEffect(() => {
    const func = async () => {
      const clients = await PinAccessApiService.clients();
      const parentPins = await PinAccessApiService.parentPins(pinId);
      const permissionGroups = await PermissionApiService.getPermissionGroupsByRoleId(AccessRole.GUEST);
      setClients(clients);
      setParentPins(parentPins);
      setPermissionGroups(permissionGroups);
      form.resetFields(['permissionGroupId']);
    };
    if (hasPinAccess) {
      func();
    }
  }, []);

  useEffect(() => {
    setPinId(pinIdFromProps(routeProps.id));
  }, [routeProps.id]);

  useEffect(() => {
    fetchPinAccess();
  }, [pinId]);

  const fetchPinAccess = () => {
    if (pinId != null) {
      setLoading(true);
      PinAccessApiService.getPinDetails(pinId)
        .then((res) => {
          setDto(res);
        })
        .catch(() => {
          notification.error({ message: 'Failed to fetch' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    form.resetFields();
    setChangesPending(false);
    setKnownClientBasedOnPermissions();
  }, [dto]);

  const onChange = () => {
    if (!changesPending) {
      setChangesPending(true);
    }
  };

  const setKnownClientBasedOnPermissions = () => {
    const hasClient = dto.clientId != undefined && dto.clientName != undefined;
    if (hasUnlinkedPinAccess) {
      const isNew = routeProps.id == NEW ? true : hasClient;
      setKnownClient(isNew);
      form.setFieldsValue({ knownClient: isNew });
    } else if (hasLinkedPinAccess) {
      setKnownClient(true);
      form.setFieldsValue({ knownClient: true });
    }
  };

  const savePin = () => {
    form.validateFields().then(() => {
      setLoading(true);
      const model = form.getFieldsValue();

      PinAccessApiService.savePin(model)
        .then((res) => {
          setChangesPending(false);
          notification.success({ message: 'PIN Saved!' });
          HistoryUtil.replace(Routes.generate(Routes.ADMIN_GUEST_ACCESS_DETAILS, { id: res }));
          if (pinId != null) {
            fetchPinAccess();
          }
        })
        .catch((error) => {
          notification.error({ message: error.message, description: error.description });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const title = pinId == null ? 'New PIN' : 'Pin ' + pinId;

  const renderClientInput = () => {
    if (hasPinAccess) {
      if (knownClient) {
        return (
          <FormItem name="clientId" label="Client" required rules={[{ required: true, message: 'Required' }]}>
            <Select allowClear showSearch optionFilterProp="children">
              {clients.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        );
      }

      return (
        <FormItem name="clientName" label="Employer Name">
          <Input />
        </FormItem>
      );
    }
  };

  return (
    <Content>
      <Spin spinning={loading}>
        <Row justify="space-between" style={{ marginBottom: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Routes.ADMIN_GUEST_ACCESS}>Guest Access</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{loading ? null : title}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        <Row>
          <Col xs={24}>
            <Row className="content-header" justify="space-between">
              <Col xs={24} lg={10} xl={13} xxl={14}>
                {loading ? null : <PageTitle title={title} />}
              </Col>
              <Col>
                {hasPinAccess ? (
                  <Space>
                    <Button
                      shape="round"
                      onClick={() => {
                        HistoryUtil.push(Routes.ADMIN_GUEST_ACCESS);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      ghost
                      shape="round"
                      onClick={() => {
                        form.resetFields();
                        setChangesPending(false);
                      }}
                    >
                      Reset
                    </Button>
                    <Button type="primary" shape="round" onClick={savePin}>
                      Save
                    </Button>
                  </Space>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <PageStayPrompt when={changesPending} />
          <Form
            form={form}
            layout="vertical"
            onValuesChange={onChange}
            style={{ width: '100%' }}
            initialValues={{
              ...dto,
              expirationDate: dto.expirationDate ? moment(dto.expirationDate) : undefined,
              createdOn: dto.createdOn ? moment(dto.createdOn) : undefined,
              updatedOn: dto.updatedOn ? moment(dto.updatedOn) : undefined,
              permissionGroupId: dto.permissionGroupId
                ? dto.permissionGroupId
                : permissionGroups.find((x) => x.name == PermissionGroupNames.CREATEPNFPINWITHNOPERSONALPIN)?.id,
            }}
          >
            <FormItem hidden name="id"></FormItem>
            <Row gutter={[16, 16]}>
              <Col sm={24} md={8}>
                <FormItem
                  name="pin"
                  label="PIN"
                  required
                  rules={[
                    { required: true, message: 'Required' },
                    { min: 5, message: 'PIN must be at least 5 characters.' },
                  ]}
                >
                  <Input readOnly={pinId != null} />
                </FormItem>
              </Col>

              <Col sm={24} md={4}>
                <FormItem name="expirationDate" label="Expires">
                  <DatePicker2
                    showTime
                    allowClear={true}
                    placeholder="Optional"
                    disabled={individualPin || parentExp}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              {individualPin ? (
                <>
                  <Col sm={24} md={12}>
                    <FormItem name="userEmail" label="User Email">
                      <Input readOnly />
                    </FormItem>
                  </Col>
                </>
              ) : (
                <>
                  {hasUnlinkedPinAccess ? (
                    <Col sm={4} md={2}>
                      <FormItem name="knownClient" label="Known Client" valuePropName="checked">
                        <Switch
                          onChange={(v) => {
                            setKnownClient(v);
                            if (!v) {
                              form.setFieldsValue({ clientName: undefined });
                            } else {
                              form.setFieldsValue({ clientId: undefined });
                            }
                          }}
                        />
                      </FormItem>
                    </Col>
                  ) : null}

                  <Col sm={20} md={10}>
                    {renderClientInput()}
                  </Col>
                </>
              )}
            </Row>
            <Row gutter={[16, 16]}>
              <Col sm={24} md={6}>
                <FormItem name="permissionGroupId" label="Permission Group" required rules={[{ required: true, message: 'Required' }]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    showArrow={hasPermission(Permission.GENERAL_EMPLOYERPINACCESS_EDITPERMISSIONGROUP)}
                    disabled={!hasPermission(Permission.GENERAL_EMPLOYERPINACCESS_EDITPERMISSIONGROUP)}
                  >
                    {permissionGroups.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col sm={24} md={6}>
                <FormItem name="parentPinId" label="Parent PIN">
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={individualPin}
                    placeholder="Optional"
                  >
                    {parentPins.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col sm={24} md={12}>
                <FormItem name="note" label="Notes">
                  <Input.TextArea autoSize={{ minRows: 2, maxRows: 8 }} />
                </FormItem>
              </Col>
            </Row>
            {pinId != null ? (
              <>
                <Divider />
                <Row gutter={[16, 16]}>
                  <Col sm={6}>
                    <FormItem name="createdBy" label="Created By">
                      <Input readOnly />
                    </FormItem>
                  </Col>
                  <Col sm={6}>
                    <FormItem name="createdOn" label="Created On">
                      <DatePicker2 disabled showTime />
                    </FormItem>
                  </Col>
                  <Col sm={6}>
                    <FormItem name="updatedBy" label="Updated By">
                      <Input readOnly />
                    </FormItem>
                  </Col>
                  <Col sm={6}>
                    <FormItem name="updatedOn" label="Updated On">
                      <DatePicker2 disabled showTime />
                    </FormItem>
                  </Col>
                </Row>
              </>
            ) : null}
          </Form>
        </Row>
      </Spin>
    </Content>
  );
};

export default GuestAccessDetailsPage;
