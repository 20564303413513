// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import PnfDTO from '../models/PnfDTO';
import PnfQueryDTO from '../models/PnfQueryDTO';
import PnfResultDTO from '../models/PnfResultDTO';
import PnfQueryResultDTO from '../models/PnfQueryResultDTO';
import ResultDTO from '../models/ResultDTO';
import RelationDTO from '../models/RelationDTO';
import PnfServicePartnerDTO from '../models/PnfServicePartnerDTO';
import MemberSearchDTO from '../models/MemberSearchDTO';

export class ProviderNominationsApiService extends BaseApi {

    // post: api/provider-nomination/submit
    public submit(dto: PnfDTO): Promise<PnfResultDTO> {
        let url = `api/provider-nomination/submit`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PnfResultDTO>(dto, 'post', url, true, false);
    }

    // get: api/provider-nomination/GetUnretrievedCount
    public getUnretrievedCount(): Promise<number> {
        let url = `api/provider-nomination/GetUnretrievedCount`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, number>(null, 'get', url, true, false);
    }

    // post: api/provider-nomination/Query
    public query(queryDto: PnfQueryDTO): Promise<PnfQueryResultDTO> {
        let url = `api/provider-nomination/Query`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PnfQueryResultDTO>(queryDto, 'post', url, true, false);
    }

    // get: api/provider-nomination/GetDraftOrPrePopulate
    public getDraftOrPrePopulate(): Promise<PnfDTO> {
        let url = `api/provider-nomination/GetDraftOrPrePopulate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PnfDTO>(null, 'get', url, true, false);
    }

    // post: api/provider-nomination/SaveDraft
    public saveDraft(dto: PnfDTO): Promise<ResultDTO> {
        let url = `api/provider-nomination/SaveDraft`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // post: api/provider-nomination/DeleteDraft
    public deleteDraft(): Promise<ResultDTO> {
        let url = `api/provider-nomination/DeleteDraft`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/provider-nomination/GetClients
    public getClients(): Promise<RelationDTO[]> {
        let url = `api/provider-nomination/GetClients`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RelationDTO[]>(null, 'post', url, true, false);
    }

    // post: api/provider-nomination/GetServicePartner
    public getServicePartner(): Promise<PnfServicePartnerDTO> {
        let url = `api/provider-nomination/GetServicePartner`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PnfServicePartnerDTO>(null, 'post', url, true, false);
    }

    // post: api/provider-nomination/GetMembers?searchString=${encodeURIComponent(searchString)}&clientId=${clientId}
    public autoCompleteMembers(searchString: string, clientId: number): Promise<MemberSearchDTO[]> {
        let url = `api/provider-nomination/GetMembers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (searchString != null) {
            url += `${prefix}searchString=${encodeURIComponent(searchString)}`;
            prefix = '&';
        }
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, MemberSearchDTO[]>(null, 'post', url, true, false);
    }
}
var service = new ProviderNominationsApiService();
export default service;
