// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface ShoppableServiceRequestDTO { 
    serviceQueryString: string | null;
    taxonomyCode: string | null;
    billingCodeType: string | null;
    billingCode: string | null;
    cursor: string | null;
}
const ShoppableServiceRequestDTO = {
    create: (initValues?: Partial<ShoppableServiceRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            serviceQueryString: "",
            taxonomyCode: "",
            billingCodeType: "",
            billingCode: "",
            cursor: "",
        },
        initValues);
    }
};

export default ShoppableServiceRequestDTO;