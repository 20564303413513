import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import React, { ReactNodeArray, useEffect, useRef } from 'react';
import ProviderType from '../../consts/ProviderType';
import ProviderDTO from '../../models/ProviderDTO';
import Renderers from '../../utils/Renderers';

interface SearchResultCardProps {
  provider: ProviderDTO;
  isSelected: boolean;
  onClick?: (id: number) => void;
  useMapStyle?: boolean;
}

const selectedCardStyle = {
  borderColor: '#D2292D',
  borderWidth: '2px',
};

const cardStyle = {
  width: '99%',
  marginBottom: '2%',
};

const ProviderCardComponent = (props: SearchResultCardProps) => {
  const cardRef = useRef<HTMLElement>();

  if (!props.useMapStyle) {
    useEffect(() => {
      if (props.isSelected && cardRef.current) {
        cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, [props.isSelected]);
  }

  return (
    <div ref={props.useMapStyle ? undefined : (cardRef as React.RefObject<HTMLDivElement>)} key={props.provider.id}>
      <Card
        size="small"
        title={
          <h2
            style={{ marginBottom: 0, paddingBottom: 0, wordWrap: 'break-word', whiteSpace: 'normal', lineHeight: 1.2 }}
          >
            <strong>{props.provider.name}</strong>
          </h2>
        }
        extra={
          <h2 style={{ marginBottom: 0, paddingBottom: 0, color: 'rgba(0, 0, 0, 0.6)' }}>
            {buildDistanceString(props.provider.metersAway)}
          </h2>
        }
        className={props.useMapStyle ? '' : 'list-card'}
        style={{
          ...(props.isSelected ? selectedCardStyle : {}),
          ...(props.useMapStyle
            ? {
                padding: '15px 20px',
                width: 400,
              }
            : cardStyle),
        }}
        bordered={!props.useMapStyle}
        headStyle={{ borderBottom: 0 }}
        bodyStyle={{ paddingTop: 0 }}
        onDoubleClick={(e) => {
          e.preventDefault();
          if (props.onClick) {
            props.onClick(props.provider.id);
          }
        }}
      >
        <Row gutter={12} justify="space-between" align="bottom" style={{ flexWrap: 'nowrap' }}>
          <Col style={{ maxWidth: '80%', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            <Row style={{ marginBottom: 10 }}>
              <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                <span>
                  <strong>Type:&nbsp;</strong>
                </span>
                <span style={{ marginRight: 12, color: 'rgba(0, 0, 0, 0.6)' }}>
                  {buildProviderTypeString(props.provider)}&nbsp;
                </span>
                <span>
                  <strong>Specialty:&nbsp;</strong>
                </span>
                <span title={props.provider.specialty || undefined} style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                  {props.provider.specialty}&nbsp;
                </span>
              </div>
            </Row>
            <Row align="middle">
              <div style={{ marginBottom: 0, color: 'rgba(0, 0, 0, 1)', fontWeight: 500, lineHeight: 1.4 }}>
                {Renderers.phoneNumberLink(props.provider.phoneNumber ?? '')}
              </div>
              <h4 style={{ marginLeft: 12, marginBottom: 0 }}>{'NPI# ' + props.provider.npi}</h4>
            </Row>
            <div
              style={{
                marginTop: '0.75em',
                marginBottom: 0,
                color: 'rgba(0, 0, 0, 1)',
                fontWeight: 500,
                lineHeight: 1.3,
                fontSize: '12px',
              }}
            >
              {buildAddress(props.provider)}
            </div>
          </Col>
          <Col>
            {props.isSelected ? (
              <div>
                Selected <CheckCircleFilled style={{ color: '#52c41a' }} />
              </div>
            ) : (
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  if (props.onClick) {
                    props.onClick(props.provider.id);
                  }
                }}
              >
                Select
              </Button>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const SearchResultCard = React.memo(ProviderCardComponent);

function buildProviderTypeString(provider: ProviderDTO): string {
  return provider.providerType === ProviderType.CLINIC ? 'Facility' : 'Practitioner';
}

function buildDistanceString(meters: number): string {
  const miles = meters / 1609.344;
  if (miles < 0.25) {
    return '< 0.25 mi';
  } else {
    const miles25 = Math.round(miles * 4) / 4;
    return `${miles25.toFixed(2)} mi`;
  }
}

function buildAddress(provider: ProviderDTO): ReactNodeArray {
  return [provider.address1, ' ', provider.address2, '\n', provider.city, ' ', provider.state, ' ', provider.zipcode]
    .map((addressData: string | null, i) => {
      if (addressData === '\n') {
        return <br key={i} />;
      } else if (addressData === ' ') {
        return <span key={i}>&nbsp;</span>;
      } else if (addressData?.trim()?.length ?? -1 > 0) {
        if (addressData?.trim()) {
          return <span key={i}>{addressData.trim()}</span>;
        }
        return null;
      } else {
        return null;
      }
    })
    .filter((e) => e !== null);
}

export default SearchResultCard;
