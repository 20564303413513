import { LockOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, notification, Popconfirm, Row, Select, Space, Switch, Tabs, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import LookupsService from '../../../api/LookupsApiService';
import Permission from '../../../consts/Permission';
import Role from '../../../consts/Role';
import useHasPermission from '../../../hooks/useHasPermission';
import PermissionDTO from '../../../models/PermissionDTO';
import PermissionGroupDetailDTO from '../../../models/PermissionGroupDetailDTO';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import PermissionGuard from '../../shared/PermissionGuard';
import PermissionGroupPermissionTable from './PermissionGroupPermissionTable';
import PermissionGroupRelationshipTable from './PermissionGroupRelationshipTable';

const { TabPane } = Tabs;
const FormItem = Form.Item;

export type ActiveTabType = 'relationships' | 'permissions';

interface PermissionGroupEditorProps {
  group: PermissionGroupDetailDTO | null;
  activeTab?: ActiveTabType;
  onCancel: () => void;
  onSave: (
    name: string,
    changedPermissions: { [id: number]: boolean },
    role: number,
    cb?: (success: boolean) => void
  ) => void;
  onDelete: (id: number) => void;
}

const PermissionGroupDetails = (props: PermissionGroupEditorProps) => {
  const [form] = Form.useForm();
  const [permissions, setPermissions] = useState<{ [id: number]: PermissionDTO }>({});
  const [showDisabledPermissions, setShowDisabledPermissions] = useState(false);
  const [activeTab, setActiveTab] = useState(props.activeTab ?? 'permissions');
  const [changesPending, setChangesPending] = useState(false);
  const [changedPermissions, setChangedPermissions] = useState<{ [id: number]: boolean }>({});
  const hasPermission = useHasPermission();

  const onFinish = (values: any) => {
    props.onSave(values.name, changedPermissions, values.roleId, (success) => {
      if (success) {
        notification.success({ message: 'Permission Group Updated' });
        setChangesPending(false);
        setChangedPermissions({});
      }
    });
  };

  const onChange = () => {
    if (!changesPending) {
      setChangesPending(true);
    }
  };

  const onCancel = () => {
    if (changesPending) {
      if (confirm('Pending changes, are you sure you want to cancel?')) {
        setChangesPending(false);
        setTimeout(() => {
          props.onCancel();
        }, 1);
      }
    } else {
      props.onCancel();
    }
  };

  const permissionToggled = (id: number, enabled: boolean) => {
    if (!changesPending) {
      setChangesPending(true);
    }
    const current = changedPermissions;
    current[id] = enabled;
    setChangedPermissions({ ...current });
  };

  const changeAll = (enabled: boolean) => {
    const newChanged = {};
    // Get list of unmanaged permissions in group
    const unmanagedPermissionIds =
      props.group?.permissions?.filter((p) => !p.isManaged).map((p) => p.permissionId.toString()) ?? [];
    // Filter all permissions down to ones that are not unmanaged in the group
    const otherPermissions = Object.keys(permissions).filter((p) => !unmanagedPermissionIds.includes(p));
    // Set all unmanaged permissions to `enabled`
    otherPermissions.forEach((k) => (newChanged[parseInt(k)] = enabled));

    // State update
    setChangedPermissions(newChanged);
    setChangesPending(true);
  };

  useEffect(() => {
    form.resetFields();
    setChangesPending(false);
    setChangedPermissions({});
  }, [props.group]);

  useEffect(() => {
    setActiveTab(props.activeTab ?? 'permissions');
  }, [props.activeTab]);

  useEffect(() => {
    LookupsService.getPermissions().then((perms) => {
      setPermissions(perms.reduce((a, x) => ({ ...a, [x.id]: x }), {}));
    });
  }, []);

  if (props.group === null) {
    return null;
  }

  const checkedPermissions =
    props.group.permissions?.reduce((a, x) => ({ ...a, [x.permission?.id ?? 0]: true }), {}) ?? {};
  Object.keys(changedPermissions).forEach((p) => {
    const pInt = parseInt(p);
    const change = changedPermissions[p];
    checkedPermissions[pInt] = change;
  });

  return (
    <>
      <PageStayPrompt when={changesPending} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onChange={onChange}
        style={{ width: '100%' }}
        initialValues={props.group}
      >
        <Row justify="space-between" align="bottom" gutter={24} style={{ flexWrap: 'wrap' }}>
          <Col sm={24} md={12}>
            <FormItem
              name="name"
              label="Group Name"
              rules={[
                {
                  required: true,
                  message: 'Name is required',
                },
              ]}
            >
              <Input
                readOnly={!props.group.isManaged || !hasPermission(Permission.ADMIN_PERMISSIONGROUPS_DETAILS_EDIT)}
                prefix={
                  props.group.isManaged ? undefined : (
                    <Tooltip title="Unmanaged Group" placement="bottomLeft">
                      <LockOutlined />
                    </Tooltip>
                  )
                }
              />
            </FormItem>
          </Col>
          <Col sm={24} md={12} style={{ textAlign: 'right' }}>
            <PermissionGuard permissionId={Permission.ADMIN_PERMISSIONGROUPS_DETAILS_EDIT}>
              <FormItem name="nan">
                <Space>
                  {props.group.isManaged && props.group.id > 0 ? (
                    <Popconfirm
                      title="Are you sure you want to delete this group?"
                      onConfirm={() => {
                        props.onDelete(props.group?.id ?? 0);
                      }}
                    >
                      <Button shape="round" type="ghost">
                        Delete
                      </Button>
                    </Popconfirm>
                  ) : null}

                  <Button shape="round" onClick={onCancel}>
                    Cancel
                  </Button>

                  <Button htmlType="submit" type="primary" shape="round" disabled={!changesPending}>
                    Save
                  </Button>
                </Space>
              </FormItem>
            </PermissionGuard>
          </Col>
        </Row>
        <Row justify="space-between" align="bottom" gutter={24}>
          <Col sm={24} md={12}>
            <FormItem name="roleId" label="Role">
              <Select
                disabled={!props.group.isManaged || !hasPermission(Permission.ADMIN_PERMISSIONGROUPS_DETAILS_EDIT)}
                onSelect={() => onChange()}
              >
                <Select.Option value={0} disabled hidden>
                  Select a role
                </Select.Option>
                <Select.Option value={Role.API}>Api</Select.Option>
                <Select.Option value={Role.BROKER}>Broker</Select.Option>
                <Select.Option value={Role.CLAIMDOC}>ClaimDOC</Select.Option>
                <Select.Option value={Role.DPC}>DPC</Select.Option>
                <Select.Option value={Role.EMPLOYER}>Employer</Select.Option>
                <Select.Option value={Role.MEMBER}>Member</Select.Option>
                <Select.Option value={Role.OTHERCOMPANY}>Other Company</Select.Option>
                <Select.Option value={Role.TPA}>TPA</Select.Option>
                <Select.Option value={Role.GUEST} hidden>
                  Guest
                </Select.Option>
              </Select>
            </FormItem>
          </Col>
          <Col sm={24} md={12}></Col>
        </Row>
      </Form>

      <Tabs
        defaultActiveKey="permissions"
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key === 'relationships' ? 'relationships' : 'permissions')}
        tabBarExtraContent={
          (activeTab ?? 'permissions') === 'permissions' ? (
            <Space>
              <Popconfirm
                title="Are you sure you want to enable all permissions for this group?"
                onConfirm={() => changeAll(true)}
              >
                <Button size="small" shape="round" type="primary">
                  Enable All
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to disable all permissions for this group?"
                onConfirm={() => changeAll(false)}
              >
                <Button size="small" shape="round">
                  Disable All
                </Button>
              </Popconfirm>
              <Switch
                checkedChildren="Showing All"
                unCheckedChildren="Show All"
                checked={showDisabledPermissions}
                onChange={(e) => setShowDisabledPermissions(e)}
              />
            </Space>
          ) : null
        }
      >
        <TabPane tab="Permissions" key="permissions">
          <PermissionGroupPermissionTable
            group={props.group}
            lookup={permissions}
            editable={hasPermission(Permission.ADMIN_PERMISSIONGROUPS_DETAILS_EDIT)}
            showDisabled={showDisabledPermissions}
            onPermissionChange={permissionToggled}
            checkedPermissions={checkedPermissions}
          />
        </TabPane>

        {props.group.id > 0 ? (
          <TabPane tab="Relationships" key="relationships">
            <PermissionGroupRelationshipTable group={props.group} />
          </TabPane>
        ) : null}
      </Tabs>
    </>
  );
};

export default PermissionGroupDetails;
