import { EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Card, Image, Button, Space } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Routes from '../../consts/Routes';
import MemberCardDTO from '../../models/MemberCardDTO';

interface MemberCardDisplayProps {
  card: MemberCardDTO;
}

const MemberCardDisplay = (props: MemberCardDisplayProps) => {
  const card = props.card;
  return (
    <>
      <Card
        title={card.cardName}
        style={{ minWidth: 325 }}
        className="member-card-single"
        extra={
          <NavLink to={Routes.generate(Routes.MEMBER_CARDS_DETAIL, { id: card.id })}>
            <Button type="link" icon={<EditOutlined />} />
          </NavLink>
        }
      >
        <Space style={{ justifyContent: 'center' }} wrap>
          {card.frontUrl ? (
            <>
              {card.frontContentType !== 'application/pdf' ? (
                <Image alt={'Front Image'.concat(card.cardName ?? '')} width={140} src={card.frontUrl} />
              ) : (
                <a
                  href={card.frontUrl}
                  download={`${card.cardName}-front-image.pdf`}
                  style={{
                    maxHeight: 140,
                    width: 140,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    fontSize: 12,
                    textDecoration: 'none',
                  }}
                >
                  <FilePdfOutlined style={{ marginBottom: 5, fontSize: 58 }} />
                  Download Front PDF
                </a>
              )}
            </>
          ) : null}

          {card.backUrl ? (
            <>
              {card.backContentType !== 'application/pdf' ? (
                <Image alt={'Back Image'.concat(card.cardName ?? '')} width={140} src={card.backUrl} />
              ) : (
                <a
                  href={card.backUrl}
                  download={`${card.cardName}-back-image.pdf`}
                  style={{
                    maxHeight: 140,
                    width: 140,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    fontSize: 12,
                    textDecoration: 'none',
                  }}
                >
                  <FilePdfOutlined style={{ marginBottom: 5, fontSize: 58 }} />
                  Download Back PDF
                </a>
              )}
            </>
          ) : null}
        </Space>
      </Card>
    </>
  );
};

export default MemberCardDisplay;
