import { Breadcrumb, Button, Col, Form, Input, Layout, notification, Popconfirm, Row, Select, Space, Spin } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import MailroomApiService from '../../api/MailroomApiService';
import PageTitle from '../../components/shared/PageTitle';
import PermissionGuard from '../../components/shared/PermissionGuard';
import MailroomFileStatuses from '../../consts/MailroomFileStatuses';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import MailroomFileDetailDTO from '../../models/MailroomFileDetailDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';

interface RouteProps {
  id: string;
  tab: string;
}

const MailroomFileDetailPage = () => {
  const routeProps = useParams<RouteProps>();
  const [loading, setLoading] = useState(true);

  const idChanged = () => {
    const parsedId = parseInt(routeProps.id);

    if (isNaN(parsedId)) {
      HistoryUtil.replace(Routes.generate(Routes.MAILROOM_FILE_LIST, { tab: routeProps.tab }));
    }
    return parsedId;
  };

  const [mailroomFileId, setMailroomFileId] = useState<number>(idChanged());
  const [mailroomFileDetail, setMailroomFileDetail] = useState<MailroomFileDetailDTO>(MailroomFileDetailDTO.create());
  const [changesPending, setChangesPending] = useState(false);
  const [form] = Form.useForm();
  const hasPermission = useHasPermission();

  const onChange = () => {
    if (!changesPending) {
      setChangesPending(true);
    }
  };

  const onSave = (values: any) => {
    setLoading(true);
    const model = {
      ...mailroomFileDetail,
      status: values.status,
      notes: values.notes,
    } as MailroomFileDetailDTO;

    MailroomApiService.saveMailroomFileDetail(model)
      .then((res) => {
        setChangesPending(false);
        setMailroomFileId(res);
        fetchMailroomFile(mailroomFileId);
        notification.success({ message: 'Mailroom File Details Saved' });
      })
      .catch((err) => {
        notification.error({ message: err.message, description: err.description });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMailroomFile = (id: number) => {
    if (id != null) {
      MailroomApiService.getMailroomFileDetail(id)
        .then((file) => {
          setMailroomFileDetail(file);
        })
        .catch((err) => {
          notification.error({ message: 'Failed to load Mailroom File details.', description: err?.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setMailroomFileId(idChanged());
  }, [routeProps.id]);

  useEffect(() => {
    fetchMailroomFile(mailroomFileId);
  }, [mailroomFileId]);

  useEffect(() => {
    form.resetFields();
    setChangesPending(false);
  }, [mailroomFileDetail]);

  const rerunMailroomFile = () => {
    setLoading(true);

    MailroomApiService.rerunMailroomFile(mailroomFileId)
      .then((result) => {
        setLoading(false);

        if (result) {
          notification.success({
            message: 'File Setup queued to run',
          });
        } else {
          notification.error({
            message: 'File Setup failed to queue',
          });
        }
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const displayName = !loading
    ? `${mailroomFileDetail?.account ?? ''} - ${mailroomFileDetail?.fileName ?? ''}`.trim()
    : null;

  const readOnly = !hasPermission(Permission.UTILITIES_MAILROOM_FILE_EDIT);

  return (
    <Layout.Content className="content-padding">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={Routes.generate(Routes.MAILROOM_FILE_LIST, { tab: routeProps.tab })}>Mailroom</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{displayName}</Breadcrumb.Item>
      </Breadcrumb>
      <PageTitle title={displayName} style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        onValuesChange={onChange}
        style={{ width: '100%' }}
        initialValues={{
          ...mailroomFileDetail,
        }}
      >
        <Row justify="end">
          <Col>
            <PermissionGuard permissionId={Permission.UTILITIES_MAILROOM_FILE_EDIT}>
              <Space>
                <Button
                  ghost
                  shape="round"
                  disabled={loading || readOnly}
                  onClick={() => {
                    rerunMailroomFile();
                  }}
                >
                  Manual Run
                </Button>
                <Popconfirm
                  title="Are you sure you want to reset all changes?"
                  onConfirm={() => {
                    fetchMailroomFile(mailroomFileId);
                  }}
                >
                  <Button ghost shape="round" disabled={!changesPending}>
                    Reset
                  </Button>
                </Popconfirm>
                <Button
                  ghost
                  htmlType="button"
                  shape="round"
                  onClick={() => HistoryUtil.push(Routes.generate(Routes.MAILROOM_FILE_LIST, { tab: routeProps.tab }))}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary" shape="round" disabled={!changesPending}>
                  Save Details
                </Button>
              </Space>
            </PermissionGuard>
          </Col>
        </Row>

        <Spin spinning={loading}>
          <PageStayPrompt
            when={changesPending}
            rootRoute={Routes.generate(Routes.MAILROOM_FILE_DETAIL, {
              tab: routeProps.tab,
              id: mailroomFileId,
            })}
          />
          <Row gutter={16} style={{ paddingBottom: '10px' }}>
            <Col sm={6}>
              <Row>
                <strong>Account</strong>
              </Row>
              <Row>{mailroomFileDetail.account}</Row>
            </Col>
            <Col sm={6}>
              <Row>
                <strong>Incoming</strong>
              </Row>
              <Row>{mailroomFileDetail.incoming ? 'True' : 'False'}</Row>
            </Col>
          </Row>
          <Row gutter={16} style={{ paddingBottom: '10px' }}>
            <Col sm={6}>
              <Row>
                <strong>Original File Name</strong>
              </Row>
              <Row>{mailroomFileDetail.fileName}</Row>
            </Col>
            <Col sm={6}>
              <Row>
                <strong>New File Name</strong>
              </Row>
              <Row>{mailroomFileDetail.newFileName}</Row>
            </Col>
          </Row>
          <Row gutter={16} style={{ paddingBottom: '10px' }}>
            <Col sm={6}>
              <Row>
                <strong>File Type</strong>
              </Row>
              <Row>{mailroomFileDetail.type}</Row>
            </Col>
            <Col sm={6}>
              <Row>
                <strong>Size</strong>
              </Row>
              <Row>{mailroomFileDetail.size}</Row>
            </Col>
          </Row>
          <Row gutter={16} style={{ paddingBottom: '10px' }}>
            <Col sm={6}>
              <Row>
                <strong>Date Uploaded</strong>
              </Row>
              <Row>{moment(mailroomFileDetail.uploadDate).format('MM-DD-yyyy hh:mm:ss')}</Row>
            </Col>
            {mailroomFileDetail.processedDate ? (
              <Col sm={6}>
                <Row>
                  <strong>Date Processed</strong>
                </Row>
                <Row>{moment(mailroomFileDetail.processedDate).format('MM-DD-yyyy hh:mm:ss')}</Row>
              </Col>
            ) : null}
          </Row>
          <Row gutter={16} style={{ paddingBottom: '10px' }}>
            <Col sm={6}>
              <Row>
                <strong>Source</strong>
              </Row>
              <Row>{mailroomFileDetail.source}</Row>
            </Col>
            <Col sm={6}>
              <Row>
                <strong>Source File Path</strong>
              </Row>
              <Row>{mailroomFileDetail.inFilePath}</Row>
            </Col>
          </Row>
          <Row gutter={16} style={{ paddingBottom: '10px' }}>
            <Col sm={6}>
              <Row>
                <strong>Destination</strong>
              </Row>
              <Row>{mailroomFileDetail.destination}</Row>
            </Col>
            <Col sm={6}>
              <Row>
                <strong>Destination File Path</strong>
              </Row>
              <Row>{mailroomFileDetail.outFilePath}</Row>
            </Col>
          </Row>
          <Row gutter={16} style={{ paddingBottom: '10px' }}>
            <Col sm={6}>
              <Row>
                <strong>Archive Type</strong>
              </Row>
              <Row>{mailroomFileDetail.archiveType}</Row>
            </Col>
            <Col sm={10}>
              <Row>
                <strong>Archive Path</strong>
              </Row>
              <Row>{mailroomFileDetail.archiveFullPath}</Row>
            </Col>
          </Row>
          {mailroomFileDetail.url ? (
            <Row gutter={16} style={{ paddingBottom: '10px' }}>
              <Col sm={12}>
                <Row>
                  <strong>SharePoint Link</strong>
                </Row>
                <Row>
                  <a href={mailroomFileDetail.url ?? ''}>{mailroomFileDetail.url ?? ''}</a>
                </Row>
              </Col>
            </Row>
          ) : null}
          <Row gutter={16}>
            <Col sm={6}>
              <FormItem name="status" label="Status">
                <Select disabled={readOnly}>
                  <Select.Option value={MailroomFileStatuses.DONE}>{MailroomFileStatuses.DONE}</Select.Option>
                  <Select.Option value={MailroomFileStatuses.FAILED}>{MailroomFileStatuses.FAILED}</Select.Option>
                  <Select.Option value={MailroomFileStatuses.JUNK}>{MailroomFileStatuses.JUNK}</Select.Option>
                  <Select.Option value={MailroomFileStatuses.MANUAL}>{MailroomFileStatuses.MANUAL}</Select.Option>
                  <Select.Option value={MailroomFileStatuses.RUNNING}>{MailroomFileStatuses.RUNNING}</Select.Option>
                  <Select.Option value={MailroomFileStatuses.TRANSFERRED}>
                    {MailroomFileStatuses.TRANSFERRED}
                  </Select.Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12}>
              <FormItem name="notes" label="Notes">
                <Input.TextArea maxLength={256} readOnly={readOnly} />
              </FormItem>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Layout.Content>
  );
};

export default MailroomFileDetailPage;
