import Icon, { FileSearchOutlined } from '@ant-design/icons';
import { Card, Col, Row, Space } from 'antd';
import React, { ReactNodeArray, useEffect, useRef } from 'react';
import Permission from '../../consts/Permission';
import ProviderType from '../../consts/ProviderType';
import SvgIcons from '../../consts/SvgIcons';
import useHasPermission from '../../hooks/useHasPermission';
import ProviderDTO from '../../models/ProviderDTO';
import Renderers from '../../utils/Renderers';

const BalanceBillIcon = <FileSearchOutlined />;

interface SearchResultCardProps {
  provider: ProviderDTO;
  isSelected: boolean;
  onClick?: (provider: ProviderDTO) => void;
  useMapStyle?: boolean;
}

const selectedCardStyle = {
  borderColor: '#D2292D',
  borderWidth: '2px',
};

const cardStyle = {
  width: '99%',
  marginBottom: '2%',
};

const ProviderCardComponent = (props: SearchResultCardProps) => {
  const cardRef = useRef<HTMLElement>();
  const hasPermission = useHasPermission();

  if (!props.useMapStyle) {
    useEffect(() => {
      if (props.isSelected && cardRef.current) {
        cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, [props.isSelected]);
  }

  const getAgreementAddons = (provider: ProviderDTO) => {
    const code = <>{provider.agreementCode}</>;

    const types: React.ReactNode[] = [];
    if (provider.agreementTypes) {
      Object.keys(provider.agreementTypes).forEach((t, i) => {
        if (t !== 'Global') {
          types.push(
            <abbr key={`abbr_${i}`} title={provider.agreementTypes[t].join(', ')}>
              {t}
            </abbr>
          );
        }
      });
    }

    return (
      <div style={{ fontSize: 12 }}>
        {code}
        {types && types.length > 0 ? ': ' : null}
        <Space direction="horizontal">{types}</Space>
      </div>
    );
  };

  return (
    <div ref={props.useMapStyle ? undefined : (cardRef as React.RefObject<HTMLDivElement>)} key={props.provider.id}>
      <Card
        size="small"
        title={
          <h2
            style={{ marginBottom: 0, paddingBottom: 0, wordWrap: 'break-word', whiteSpace: 'normal', lineHeight: 1.2 }}
          >
            <strong>{props.provider.name}</strong>
            {hasPermission(Permission.TOOLS_PROVIDERDIRECTORY_VIEWAGREEMENT)
              ? getAgreementAddons(props.provider)
              : null}
          </h2>
        }
        extra={
          <h2 style={{ marginBottom: 0, paddingBottom: 0, color: 'rgba(0, 0, 0, 0.6)' }}>
            {buildDistanceString(props.provider.metersAway)}
          </h2>
        }
        className={props.useMapStyle ? '' : 'list-card'}
        style={{
          ...(props.isSelected ? selectedCardStyle : {}),
          ...(props.useMapStyle
            ? {
                padding: '15px 20px',
                width: 400,
              }
            : cardStyle),
        }}
        bordered={!props.useMapStyle}
        headStyle={{ borderBottom: 0 }}
        bodyStyle={{ paddingTop: 0 }}
      >
        <Row gutter={12} justify="space-between" align="bottom" style={{ flexWrap: 'nowrap' }}>
          <Col style={{ maxWidth: '88%', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            <Row style={{ marginBottom: 10 }}>
              <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                <span>
                  <strong>Type:&nbsp;</strong>
                </span>
                <span style={{ marginRight: 12, color: 'rgba(0, 0, 0, 0.6)' }}>
                  {buildProviderTypeString(props.provider)}&nbsp;
                </span>
                <span>
                  <strong>Specialty:&nbsp;</strong>
                </span>
                <span title={props.provider.specialty || undefined} style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                  {props.provider.specialty}&nbsp;
                </span>
              </div>
            </Row>
            <Row align="middle">
              <div style={{ marginBottom: 0, color: 'rgba(0, 0, 0, 1)', fontWeight: 500, lineHeight: 1.4 }}>
                {Renderers.phoneNumberLink(props.provider.phoneNumber ?? '')}
              </div>
              <h4 style={{ marginLeft: 12, marginBottom: 0 }}>{'NPI# ' + props.provider.npi}</h4>
            </Row>
            <div
              style={{
                marginTop: '0.75em',
                marginBottom: 0,
                color: 'rgba(0, 0, 0, 1)',
                fontWeight: 500,
                lineHeight: 1.3,
                fontSize: '12px',
              }}
            >
              {buildAddress(props.provider)}
            </div>
            {props.provider.balanceBill && hasPermission(Permission.TOOLS_PROVIDERDIRECTORY_VIEWBALANCEBILL) ? (
              <div style={{ marginTop: 5 }}>
                <span style={{ color: '#1934B5' }}>
                  {BalanceBillIcon} <strong>Balance Bills</strong>{' '}
                </span>
              </div>
            ) : null}
          </Col>
          <Col>
            {!props.useMapStyle ? (
              <>
                <Icon
                  title="Click to see on map"
                  onClick={(e) => {
                    e.preventDefault();
                    if (props.onClick) {
                      props.onClick(props.provider);
                    }
                  }}
                  component={getMarkerForProvider(props.provider)}
                />
                <br />
              </>
            ) : null}
            <a href={buildDirectionsLink(props.provider)} target="_blank" rel="noopener noreferrer">
              <Icon title="Click for directions" style={{ marginTop: 10 }} component={SvgIcons.Directions} />
            </a>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const SearchResultCard = React.memo(ProviderCardComponent);

function getMarkerForProvider(provider: ProviderDTO): () => JSX.Element {
  return provider.providerType === ProviderType.CLINIC ? SvgIcons.MarkerClinic : SvgIcons.MarkerIndividual;
}

function buildProviderTypeString(provider: ProviderDTO): string {
  return provider.providerType === ProviderType.CLINIC ? 'Facility' : 'Practitioner';
}

function buildDistanceString(meters: number): string {
  const miles = meters / 1609.344;
  if (miles < 0.25) {
    return '< 0.25 mi';
  } else {
    const miles25 = Math.round(miles * 4) / 4;
    return `${miles25.toFixed(2)} mi`;
  }
}

function buildAddress(provider: ProviderDTO): ReactNodeArray {
  return [provider.address1, ' ', provider.address2, '\n', provider.city, ' ', provider.state, ' ', provider.zipcode]
    .map((addressData: string | null, i) => {
      if (addressData === '\n') {
        return <br key={i} />;
      } else if (addressData === ' ') {
        return <span key={i}>&nbsp;</span>;
      } else if (addressData?.trim()?.length ?? -1 > 0) {
        if (addressData?.trim()) {
          return <span key={i}>{addressData.trim()}</span>;
        }
        return null;
      } else {
        return null;
      }
    })
    .filter((e) => e !== null);
}

function buildDirectionsLink(provider: ProviderDTO): string {
  return 'https://www.google.com/maps/dir/?api=1' + '&destination=' + provider.lat + '%2C' + provider.long;
}

export default SearchResultCard;
