import { Button, Col, Form, Input, notification, Popconfirm, Row, Space, Spin, Switch } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import MailroomApiService from '../../api/MailroomApiService';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import MailroomAccountDetailDTO from '../../models/MailroomAccountDetailDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';
import PermissionGuard from '../shared/PermissionGuard';

interface MailroomAccountDetailViewProps {
  accountDto: MailroomAccountDetailDTO | null;
  refreshDto: (id: number | null) => void;
}

const MailroomAccountDetailView = (props: MailroomAccountDetailViewProps) => {
  const [loading, setLoading] = useState(false);
  const [mailroomAccountDetail, setMailroomAccountDetail] = useState<MailroomAccountDetailDTO>(
    props.accountDto || MailroomAccountDetailDTO.create()
  );
  const [changesPending, setChangesPending] = useState(false);
  const [form] = Form.useForm();
  const hasPermission = useHasPermission();

  const onChange = () => {
    if (!changesPending) {
      setChangesPending(true);
    }
  };

  const onSave = (values: any) => {
    setLoading(true);
    const model = { ...mailroomAccountDetail, ...values } as MailroomAccountDetailDTO;

    MailroomApiService.saveMailroomAccountDetail(model)
      .then((res) => {
        setChangesPending(false);
        props.refreshDto(res);
        notification.success({ message: 'Mailroom Account Saved' });
      })
      .catch((err) => {
        notification.error({ message: err.message, description: err.description });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    form.resetFields();
    setMailroomAccountDetail(props.accountDto || MailroomAccountDetailDTO.create());
    setChangesPending(false);
    form.setFieldsValue(props.accountDto);
  }, [props.accountDto]);

  const readOnly = !hasPermission(Permission.UTILITIES_MAILROOM_ACCOUNT_EDIT);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSave}
      onValuesChange={onChange}
      style={{ width: '100%' }}
      initialValues={mailroomAccountDetail}
    >
      <Row justify="end">
        <Col>
          <PermissionGuard permissionId={Permission.UTILITIES_MAILROOM_ACCOUNT_EDIT}>
            <Space>
              <Popconfirm
                title="Are you sure you want to reset all changes?"
                onConfirm={() => {
                  props.refreshDto(props.accountDto?.id || null);
                }}
              >
                <Button ghost shape="round" disabled={!changesPending}>
                  Reset
                </Button>
              </Popconfirm>

              <Button
                ghost
                htmlType="button"
                shape="round"
                onClick={() => HistoryUtil.push(Routes.generate(Routes.MAILROOM_ACCOUNT_LIST))}
              >
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" shape="round" disabled={!changesPending}>
                Save Details
              </Button>
            </Space>
          </PermissionGuard>
        </Col>
      </Row>

      <Spin spinning={loading}>
        <PageStayPrompt when={changesPending} rootRoute={Routes.generate(Routes.MAILROOM_ACCOUNT_NEW)} />
        <Row gutter={16}>
          <Col sm={6}>
            <FormItem name="account" label="Account">
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
          <Col sm={6}>
            <FormItem name="partner" label="Partner">
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={6}>
            <FormItem name="partnerCode" label="Partner Code">
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
          <Col sm={6}>
            <FormItem name="accountType" label="Account Type">
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <FormItem name="specialSecurity" label="Special Security">
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <FormItem name="baseUrl" label="Base Url">
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <FormItem name="contacts" label="Contacts">
              <Input.TextArea disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <FormItem name="notes" label="Notes">
              <Input.TextArea disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormItem name="archived" label="Archived">
              <Switch defaultChecked={mailroomAccountDetail.archived} disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default MailroomAccountDetailView;
