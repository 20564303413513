import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import UserService from '../../api/UserApiService';
import UserDetailRelationships from '../../components/admin/Users/UserDetailRelationships';
import UserDetailSecurity from '../../components/admin/Users/UserDetailSecurity';
import PageTitle from '../../components/shared/PageTitle';
import PermissionGuard from '../../components/shared/PermissionGuard';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import UserTabTypes from '../../consts/UserTabTypes';
import useHasPermission from '../../hooks/useHasPermission';
import UserDetailDTO from '../../models/UserDetailDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
} from 'antd';
import UserStatus from '../../consts/UserStatus';

const DefaultTab = 'details';
interface RouteProps {
  type: string;
  id: string;
  tab?: string;
}

const UserDetailPage = () => {
  const routeProps = useParams<RouteProps>();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(routeProps.tab || DefaultTab);

  const idChanged = () => {
    const parsedId = parseInt(routeProps.id);

    if (isNaN(parsedId)) {
      HistoryUtil.replace(Routes.generate(Routes.ADMIN_USER_LIST, { type: routeProps.type }));
    }
    return parsedId;
  };

  const userTypeLabel = (type: string) => {
    switch (type) {
      case UserTabTypes.MEMBERS:
        return 'Employer/Employee';
      case UserTabTypes.TPAS:
        return 'TPA Users';
      case UserTabTypes.DPCS:
        return 'DPC Users';
      case UserTabTypes.OTHERCOMPANIES:
        return 'Other Company Users';
      case UserTabTypes.BROKERS:
        return 'Brokers';
      case UserTabTypes.CLAIMDOC:
        return 'ClaimDOC Users';
    }
  };

  const [userId, setUserId] = useState<number>(idChanged());
  const [userDetail, setUserDetail] = useState<UserDetailDTO>(UserDetailDTO.create());
  const [changesPending, setChangesPending] = useState(false);
  const [form] = Form.useForm();
  const hasPermission = useHasPermission();

  const onChange = () => {
    if (!changesPending) {
      setChangesPending(true);
    }
  };

  const onSave = (values: any) => {
    const model = {
      ...userDetail,
      firstName: values.firstName,
      lastName: values.lastName,
      requireMfa: values.requireMfa,
      userStatusId: values.userStatusId,
    } as UserDetailDTO;
    if (values.claimDocPermissionGroups) {
      model.claimDocPermissionGroups = values.claimDocPermissionGroups;
    }

    UserService.saveUserDetail(model)
      .then(() => {
        fetchUser(userId);
        notification.success({ message: 'User Saved' });
      })
      .catch((err) => {
        notification.error({ message: err.message, description: err.description });
      });
  };

  const fetchUser = (id: number) => {
    setLoading(true);
    UserService.getUserDetail(id)
      .then((user) => {
        setUserDetail(user);
      })
      .catch((err) => {
        setUserDetail(UserDetailDTO.create());
        notification.error({ message: 'Failed to load user data.', description: err?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setUserId(idChanged());
  }, [routeProps.id]);

  useEffect(() => {
    fetchUser(userId);
  }, [userId]);

  useEffect(() => {
    form.resetFields();
    setChangesPending(false);
  }, [userDetail]);

  useEffect(() => {
    setSelectedTab(routeProps.tab || DefaultTab);
  }, [routeProps.tab]);

  const resetMfa = () => {
    setLoading(true);
    UserService.resetUsersMfa(userId)
      .then(() => {
        fetchUser(userId);
        notification.success({ message: 'Mfa Reset' });
      })
      .catch((err) => {
        notification.error({ message: err.message, description: err.description });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const displayName = `${userDetail?.firstName ?? ''} ${userDetail?.lastName ?? ''} ${
    userDetail?.username ?? ''
  }`.trim();

  const readOnly = !hasPermission(Permission.ADMIN_USERS_DETAILS_EDIT);

  const detailRender = () => (
    <>
      <Row gutter={16}>
        <Col sm={6}>
          <FormItem name="firstName" label="First Name">
            <Input readOnly={readOnly} />
          </FormItem>
        </Col>
        <Col sm={6}>
          <FormItem name="lastName" label="Last Name">
            <Input readOnly={readOnly} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col sm={4}>
          <FormItem name="username" label="Username">
            <Input readOnly />
          </FormItem>
        </Col>
        <Col sm={4}>
          <FormItem name="email" label="Email">
            <Input readOnly />
          </FormItem>
        </Col>
        <Col sm={4}>
          <FormItem name="phoneNumber" label="Phone Number">
            <Input readOnly />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <FormItem name="requireMfa" label="Require Mfa" valuePropName="checked">
            <Switch disabled={readOnly} />
          </FormItem>
        </Col>
        <Col sm={4}>
          <FormItem name="userStatusId" label="Status">
            <Select optionFilterProp="children" showSearch style={{ width: '100%' }}>
              <Select.Option key={UserStatus.ACTIVE} value={UserStatus.ACTIVE}>
                Active
              </Select.Option>
              <Select.Option key={UserStatus.INACTIVE} value={UserStatus.INACTIVE}>
                Inactive
              </Select.Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      {hasPermission(Permission.ADMIN_USERS_MANAGEMFA) && userDetail.mfaEnabled ? (
        <Card
          bodyStyle={{ textAlign: 'center' }}
          className="ant-card-background ant-card-shadow"
          style={{ maxWidth: 300 }}
        >
          <strong style={{ fontSize: 20 }}>MFA Enabled</strong> <br />
          Click below to reset MFA for this user.
          <br />
          <Popconfirm title="Are you sure?" onConfirm={resetMfa} placement="bottom">
            <Button ghost>Disable MFA</Button>
          </Popconfirm>
        </Card>
      ) : null}
    </>
  );

  const tabs = [
    <Tabs.TabPane tab="Details" key="details">
      {detailRender()}
    </Tabs.TabPane>,
  ];
  if (hasPermission(Permission.ADMIN_USERS_RELATIONSHIPS_VIEW)) {
    tabs.push(
      <Tabs.TabPane tab="Relationships" key="relationships">
        <UserDetailRelationships detail={userDetail} />
      </Tabs.TabPane>
    );
  }
  if (
    hasPermission(Permission.ADMIN_USERS_PERMISSIONGROUPS_VIEW) &&
    (userDetail.username?.toLocaleLowerCase().endsWith('@claim-doc.com') ?? false)
  ) {
    tabs.push(
      <Tabs.TabPane tab="ClaimDOC Permission Groups" key="permissiongroups">
        <UserDetailSecurity
          editable={hasPermission(Permission.ADMIN_USERS_PERMISSIONGROUPS_EDIT)}
          details={userDetail}
          form={form}
        />
      </Tabs.TabPane>
    );
  }

  return (
    <Layout.Content className="content-padding">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={Routes.generate(Routes.ADMIN_USER_LIST, { type: 'all' })}>Users</Link>
        </Breadcrumb.Item>
        {routeProps.type != 'all' ? (
          <Breadcrumb.Item>
            <Link to={Routes.generate(Routes.ADMIN_USER_LIST, { type: routeProps.type })}>
              {userTypeLabel(routeProps.type)}
            </Link>
          </Breadcrumb.Item>
        ) : null}
        <Breadcrumb.Item>{displayName}</Breadcrumb.Item>
      </Breadcrumb>
      <PageTitle title={displayName} style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        onValuesChange={onChange}
        style={{ width: '100%' }}
        initialValues={userDetail}
      >
        <Row justify="end">
          <Col>
            <PermissionGuard permissionId={Permission.ADMIN_USERS_DETAILS_EDIT}>
              <Space>
                <Popconfirm
                  title="Are you sure you want to reset all changes?"
                  onConfirm={() => {
                    fetchUser(userId);
                  }}
                >
                  <Button ghost shape="round" disabled={!changesPending}>
                    Reset
                  </Button>
                </Popconfirm>

                <Button
                  ghost
                  htmlType="button"
                  shape="round"
                  onClick={() => HistoryUtil.push(Routes.generate(Routes.ADMIN_USER_LIST, { type: routeProps.type }))}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary" shape="round" disabled={!changesPending}>
                  Save User
                </Button>
              </Space>
            </PermissionGuard>
          </Col>
        </Row>

        <Spin spinning={loading}>
          <PageStayPrompt
            when={changesPending}
            rootRoute={Routes.generate(Routes.ADMIN_USER_DETAIL, { id: routeProps.id, type: routeProps.type })}
          />

          <Tabs
            activeKey={selectedTab}
            onChange={(key) =>
              HistoryUtil.replace(
                Routes.generate(Routes.ADMIN_USER_DETAIL, { id: routeProps.id, tab: key, type: routeProps.type })
              )
            }
          >
            {tabs}
          </Tabs>
        </Spin>
      </Form>
    </Layout.Content>
  );
};

export default UserDetailPage;
