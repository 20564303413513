import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, notification, Row, Select, Upload, FormInstance } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useState, useEffect } from 'react';
import MemberCardApiService from '../../api/MemberCardApiService';
import LookupTableDTO from '../../models/LookupTableDTO';
import MemberCardDTO from '../../models/MemberCardDTO';

interface EditMemberCardProps {
  card: MemberCardDTO;
  onSave?: (dto: MemberCardDTO) => void;
  onCancel?: () => void;
  form: FormInstance;
}

const EditMemberCard = (props: EditMemberCardProps) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [previewTitle, setPreviewTitle] = useState('');
  const [memberCardTypes, setMemberCardTypes] = useState<LookupTableDTO[]>([]);

  const [frontImageList, setFrontImageList] = useState<UploadFile[]>([]);
  const [backImageList, setBackImageList] = useState<UploadFile[]>([]);

  const [deleteFront, setDeleteFront] = useState(false);
  const [deleteBack, setDeleteBack] = useState(false);

  useEffect(() => {
    MemberCardApiService.getMemberCardTypes().then(setMemberCardTypes);
  }, []);

  useEffect(() => {
    if (props.card.frontUrl) {
      const isPdf = props.card.frontContentType?.includes('pdf');
      const name = isPdf ? 'PDF' : '';
      setFrontImageList([
        {
          uid: '-1',
          name: name,
          status: 'done',
          url: isPdf ? undefined : props.card.frontUrl,
          thumbUrl: isPdf ? undefined : props.card.frontUrl,
        },
      ]);
    } else {
      setFrontImageList([]);
    }
  }, [props.card.frontUrl]);

  useEffect(() => {
    if (props.card.backUrl) {
      const isPdf = props.card.backContentType?.includes('pdf');
      const name = isPdf ? 'PDF' : '';
      setBackImageList([
        {
          uid: '-2',
          name: name,
          status: 'done',
          url: isPdf ? undefined : props.card.backUrl,
          thumbUrl: isPdf ? undefined : props.card.backUrl,
        },
      ]);
    } else {
      setBackImageList([]);
    }
  }, [props.card.backUrl]);

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file: any) => {
    file.preview = file.thumbUrl ? file.thumbUrl : await getBase64(file.originFileObj);
    setPreviewVisible(true);
    setPreviewImage(file.preview);
    setPreviewTitle(file.name);
  };

  const handleFrontUploadChange = ({ fileList }: { fileList: any }) => {
    setFrontImageList([...fileList]);
  };

  const beforeFrontUpload = (file: UploadFile) => {
    setDeleteFront(false);
    setFrontImageList([...frontImageList, file]);
    return false;
  };

  const handleBackUploadChange = ({ fileList }: { fileList: any }) => {
    setBackImageList([...fileList]);
  };

  const beforeBackUpload = (file: UploadFile) => {
    setDeleteBack(false);
    setBackImageList([...backImageList, file]);
    return false;
  };

  return (
    <Form
      form={props.form}
      onFinish={async (values) => {
        if (!props.onSave) {
          return;
        }

        if (frontImageList.length == 0 && backImageList.length == 0) {
          notification.error({ message: 'One image is required.' });
          return;
        }

        if (frontImageList.length > 0) {
          values.frontFile = frontImageList[0].originFileObj;
        }

        if (backImageList.length > 0) {
          values.backFile = backImageList[0].originFileObj;
        }

        values.deleteFront = deleteFront;
        values.deleteBack = deleteBack;

        props.onSave(values);
      }}
      initialValues={props.card}
      layout="vertical"
    >
      <Row>
        <Col flex={0}>
          <Form.Item label="Front of Card">
            <Upload
              accept=".jpg, .JPG, .jpeg, .JPEG, .pdf, .PDF, .png, .PNG"
              maxCount={1}
              listType="picture-card"
              fileList={frontImageList}
              beforeUpload={beforeFrontUpload}
              onChange={handleFrontUploadChange}
              onRemove={() => setDeleteFront(true)}
              onPreview={handlePreview}
            >
              {frontImageList.length >= 1 ? null : <PlusOutlined />}
            </Upload>
          </Form.Item>
        </Col>
        <Col flex={0}>
          <Form.Item label="Back of Card">
            <Upload
              accept=".jpg, .JPG, .jpeg, .JPEG, .pdf, .PDF, .png, .PNG"
              maxCount={1}
              listType="picture-card"
              fileList={backImageList}
              beforeUpload={beforeBackUpload}
              onChange={handleBackUploadChange}
              onRemove={() => setDeleteBack(true)}
              onPreview={handlePreview}
            >
              {backImageList.length >= 1 ? null : <PlusOutlined />}
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="cardName" label="Card Name" rules={[{ required: true, message: 'Required.' }]} required>
            <Input allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="cardTypeId" label="Card Type" rules={[{ required: true, message: 'Required.' }]} required>
            <Select style={{ width: '100%' }} allowClear showSearch optionFilterProp="children">
              {memberCardTypes.map((memberCardType) => {
                return (
                  <Select.Option value={memberCardType.id} key={memberCardType.id}>
                    {memberCardType.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Form>
  );
};

export default EditMemberCard;
