import { Button, Col, Row, Space, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import ClientApiService from '../../../api/ClientApiService';
import FeatureFlagEditor from '../../../components/shared/FeatureFlagEditor';
import BrokerListDTO from '../../../models/BrokerListDTO';
import ClientFeatureFlagsDTO from '../../../models/ClientFeatureFlagsDTO';
import DpcListDTO from '../../../models/DpcListDTO';
import FeatureFlagDTO from '../../../models/FeatureFlagDTO';
import OtherCompanyListDTO from '../../../models/OtherCompanyListDTO';
import TpaClientFeatureFlagsDTO from '../../../models/TpaClientFeatureFlagsDTO';
import TpaListDTO from '../../../models/TpaListDTO';
import ConditionalPopconfirm from '../../shared/ConditionalPopconfirm';
import Role from '../../../consts/Role';
import DpcClientFeatureFlagsDTO from '../../../models/DpcClientFeatureFlagsDTO';
import BrokerClientFeatureFlagsDTO from '../../../models/BrokerClientFeatureFlagsDTO';
import OtherCompanyClientFeatureFlagsDTO from '../../../models/OtherCompanyClientFeatureFlagsDTO';
import EmployerFeatureFlagsDTO from '../../../models/EmployerFeatureFlagsDTO';
import ClaimDocUserFeatureFlagsDTO from '../../../models/ClaimDocUserFeatureFlagsDTO';
import RoleName from '../../../consts/RoleName';
interface ClientConfigProps {
  clientId: number;
  readonly?: boolean;
}

const ClientConfig = (props: ClientConfigProps) => {
  const [activeTab, setActiveTab] = useState<string>(RoleName.CLAIMDOC + ',' + props.clientId);
  const [baseClientFeatureFlag, setBaseClientFeatureFlag] = useState<ClientFeatureFlagsDTO>();
  const [brokerList, setBrokerList] = useState<BrokerListDTO[]>([]);
  const [changesPending, setChangesPending] = useState(false);
  const [clientFeatureFlag, setClientFeatureFlag] = useState<ClientFeatureFlagsDTO>();
  const [dpcList, setDpcList] = useState<DpcListDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [otherCompanyList, setOtherCompanyList] = useState<OtherCompanyListDTO[]>([]);
  const [tpaList, setTpaList] = useState<TpaListDTO[]>([]);

  useEffect(() => {
    setLoading(true);
    const loaders = [];
    loaders.push(loadClientFeatureFlags(activeTab));
    loaders.push(loadClientBroker());
    loaders.push(loadClientDpcs());
    loaders.push(loadClientOtherCompanies());
    loaders.push(loadClientTpas());

    Promise.all(loaders).then(() => {
      setLoading(false);
    });
  }, [props.clientId]);

  const loadClientFeatureFlags = (key: string) => {
    const active = key.split(',');
    const activeRole = active[0];
    const activeType = active[1];

    return ClientApiService.getClientFeatureFlagsWithRoleName(props.clientId, activeRole).then((res) => {
      const activeFeatureFlags: ClientFeatureFlagsDTO = ClientFeatureFlagsDTO.create({ ...clientFeatureFlag });
      const baseFeatureFlags: ClientFeatureFlagsDTO = ClientFeatureFlagsDTO.create({ ...baseClientFeatureFlag });
      activeFeatureFlags.id = props.clientId;
      baseFeatureFlags.id = props.clientId;

      switch (activeRole) {
        case RoleName.BROKER:
          if (
            (activeFeatureFlags.brokerClientFeatureFlags?.findIndex((x) => x.brokerId?.toString() == activeType) ??
              -1) <= -1
          ) {
            activeFeatureFlags.brokerClientFeatureFlags?.pushAll(res.brokerClientFeatureFlags ?? []);
            baseFeatureFlags.brokerClientFeatureFlags?.pushAll(res.brokerClientFeatureFlags ?? []);
          }
          break;
        case RoleName.EMPLOYER:
          if (
            (activeFeatureFlags.employerFeatureFlags?.findIndex((x) => (x.featureFlags?.length ?? -1) >= 0) ?? -1) <= -1
          ) {
            activeFeatureFlags.employerFeatureFlags?.pushAll(res.employerFeatureFlags ?? []);
            baseFeatureFlags.employerFeatureFlags?.pushAll(res.employerFeatureFlags ?? []);
          }
          break;
        case RoleName.CLAIMDOC:
          if (
            (activeFeatureFlags.claimDocUserFeatureFlags?.findIndex((x) => x.clientId?.toString() == activeType) ??
              -1) <= -1
          ) {
            activeFeatureFlags.claimDocUserFeatureFlags?.pushAll(res.claimDocUserFeatureFlags ?? []);
            baseFeatureFlags.claimDocUserFeatureFlags?.pushAll(res.claimDocUserFeatureFlags ?? []);
          }
          break;
        case RoleName.DPC:
          if (
            (activeFeatureFlags.dpcClientFeatureFlags?.findIndex((x) => x.dpcId?.toString() == activeType) ?? -1) <= -1
          ) {
            activeFeatureFlags.dpcClientFeatureFlags?.pushAll(res.dpcClientFeatureFlags ?? []);
            baseFeatureFlags.dpcClientFeatureFlags?.pushAll(res.dpcClientFeatureFlags ?? []);
          }
          break;
        case RoleName.OTHERCOMPANY:
          if (
            (activeFeatureFlags.otherCompanyClientFeatureFlags?.findIndex(
              (x) => x.otherCompanyId?.toString() == activeType
            ) ?? -1) <= -1
          ) {
            activeFeatureFlags.otherCompanyClientFeatureFlags?.pushAll(res.otherCompanyClientFeatureFlags ?? []);
            baseFeatureFlags.otherCompanyClientFeatureFlags?.pushAll(res.otherCompanyClientFeatureFlags ?? []);
          }
          break;
        case RoleName.TPA:
          if (
            (activeFeatureFlags.tpaClientFeatureFlags?.findIndex((x) => x.tpaId?.toString() == activeType) ?? -1) <= -1
          ) {
            activeFeatureFlags.tpaClientFeatureFlags?.pushAll(res.tpaClientFeatureFlags ?? []);
            baseFeatureFlags.tpaClientFeatureFlags?.pushAll(res.tpaClientFeatureFlags ?? []);
          }
          break;
      }
      setClientFeatureFlag(activeFeatureFlags);
      setBaseClientFeatureFlag(JSON.parse(JSON.stringify(ClientFeatureFlagsDTO.create({ ...baseFeatureFlags }))));
    });
  };

  const loadClientBroker = () => {
    ClientApiService.getBrokersForClient(props.clientId).then((res) => {
      setBrokerList(res);
    });
  };

  const loadClientDpcs = () => {
    ClientApiService.getDpcsForClient(props.clientId).then((res) => {
      setDpcList(res);
    });
  };

  const loadClientOtherCompanies = () => {
    ClientApiService.getOtherCompaniesForClient(props.clientId).then((res) => {
      setOtherCompanyList(res);
    });
  };

  const loadClientTpas = () => {
    ClientApiService.getTpasForClient(props.clientId).then((res) => {
      setTpaList(res);
    });
  };

  const saveChanges = () => {
    setLoading(true);
    ClientApiService.saveClientFeatureFlags(clientFeatureFlag ?? ClientFeatureFlagsDTO.create()).then(() => {
      setChangesPending(false);
      setBaseClientFeatureFlag(JSON.parse(JSON.stringify(ClientFeatureFlagsDTO.create({ ...clientFeatureFlag }))));
      setLoading(false);
    });
  };

  const reset = () => {
    setClientFeatureFlag(baseClientFeatureFlag);
    setChangesPending(false);
  };

  const changeBrokerFeatureFlag = (baseFeatureFlags: FeatureFlagDTO[] | null, editedBroker: number) => {
    setChangesPending(true);
    const tempClientFeatureFlags: ClientFeatureFlagsDTO = ClientFeatureFlagsDTO.create({ ...clientFeatureFlag });
    if ((tempClientFeatureFlags?.brokerClientFeatureFlags?.findIndex((x) => x.brokerId == editedBroker) ?? 0) <= -1) {
      tempClientFeatureFlags.brokerClientFeatureFlags?.push(
        BrokerClientFeatureFlagsDTO.create({ brokerId: editedBroker, featureFlags: [] })
      );
    }
    const featureFlags = tempClientFeatureFlags?.brokerClientFeatureFlags?.find(
      (x) => x.brokerId == editedBroker
    )?.featureFlags;

    featureFlags?.forEach((x) => {
      if (baseFeatureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.remove(x);
      }
    });

    baseFeatureFlags?.forEach((x) => {
      if (featureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.push(x);
      }
    });

    setClientFeatureFlag(tempClientFeatureFlags);
  };

  const changeDpcFeatureFlag = (baseFeatureFlags: FeatureFlagDTO[] | null, editedDpc: number) => {
    setChangesPending(true);
    const tempClientFeatureFlags: ClientFeatureFlagsDTO = ClientFeatureFlagsDTO.create({ ...clientFeatureFlag });
    if ((tempClientFeatureFlags?.dpcClientFeatureFlags?.findIndex((x) => x.dpcId == editedDpc) ?? 0) <= -1) {
      tempClientFeatureFlags.dpcClientFeatureFlags?.push(
        DpcClientFeatureFlagsDTO.create({ dpcId: editedDpc, featureFlags: [] })
      );
    }
    const featureFlags = tempClientFeatureFlags?.dpcClientFeatureFlags?.find((x) => x.dpcId == editedDpc)?.featureFlags;

    featureFlags?.forEach((x) => {
      if (baseFeatureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.remove(x);
      }
    });

    baseFeatureFlags?.forEach((x) => {
      if (featureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.push(x);
      }
    });

    setClientFeatureFlag(tempClientFeatureFlags);
  };

  const changeOtherCompanyFeatureFlag = (baseFeatureFlags: FeatureFlagDTO[] | null, editedOtherCompany: number) => {
    setChangesPending(true);
    const tempClientFeatureFlags: ClientFeatureFlagsDTO = ClientFeatureFlagsDTO.create({ ...clientFeatureFlag });
    if (
      (tempClientFeatureFlags?.otherCompanyClientFeatureFlags?.findIndex(
        (x) => x.otherCompanyId == editedOtherCompany
      ) ?? 0) <= -1
    ) {
      tempClientFeatureFlags.otherCompanyClientFeatureFlags?.push(
        OtherCompanyClientFeatureFlagsDTO.create({ otherCompanyId: editedOtherCompany, featureFlags: [] })
      );
    }
    const featureFlags = tempClientFeatureFlags?.otherCompanyClientFeatureFlags?.find(
      (x) => x.otherCompanyId == editedOtherCompany
    )?.featureFlags;

    featureFlags?.forEach((x) => {
      if (baseFeatureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.remove(x);
      }
    });

    baseFeatureFlags?.forEach((x) => {
      if (featureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.push(x);
      }
    });

    setClientFeatureFlag(tempClientFeatureFlags);
  };

  const changeTpaFeatureFlag = (baseFeatureFlags: FeatureFlagDTO[] | null, editedTpa: number) => {
    setChangesPending(true);
    const tempClientFeatureFlags: ClientFeatureFlagsDTO = ClientFeatureFlagsDTO.create({ ...clientFeatureFlag });
    if ((tempClientFeatureFlags?.tpaClientFeatureFlags?.findIndex((x) => x.tpaId == editedTpa) ?? 0) <= -1) {
      tempClientFeatureFlags.tpaClientFeatureFlags?.push(
        TpaClientFeatureFlagsDTO.create({ tpaId: editedTpa, featureFlags: [] })
      );
    }
    const featureFlags = tempClientFeatureFlags?.tpaClientFeatureFlags?.find((x) => x.tpaId == editedTpa)?.featureFlags;

    featureFlags?.forEach((x) => {
      if (baseFeatureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.remove(x);
      }
    });

    baseFeatureFlags?.forEach((x) => {
      if (featureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.push(x);
      }
    });

    setClientFeatureFlag(tempClientFeatureFlags);
  };

  const changeEmployerFeatureFlag = (baseFeatureFlags: FeatureFlagDTO[] | null) => {
    setChangesPending(true);
    const tempClientFeatureFlags: ClientFeatureFlagsDTO = ClientFeatureFlagsDTO.create({ ...clientFeatureFlag });
    if ((tempClientFeatureFlags?.employerFeatureFlags?.findIndex((x) => x.clientId == props.clientId) ?? 0) <= -1) {
      tempClientFeatureFlags.employerFeatureFlags?.push(
        EmployerFeatureFlagsDTO.create({ clientId: props.clientId, featureFlags: [] })
      );
    }
    const featureFlags = tempClientFeatureFlags?.employerFeatureFlags?.find(
      (x) => x.clientId == props.clientId
    )?.featureFlags;

    featureFlags?.forEach((x) => {
      if (baseFeatureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.remove(x);
      }
    });

    baseFeatureFlags?.forEach((x) => {
      if (featureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.push(x);
      }
    });

    setClientFeatureFlag(tempClientFeatureFlags);
  };

  const changeClaimDOCFeatureFlag = (baseFeatureFlags: FeatureFlagDTO[] | null) => {
    setChangesPending(true);
    const tempClientFeatureFlags: ClientFeatureFlagsDTO = ClientFeatureFlagsDTO.create({ ...clientFeatureFlag });
    if ((tempClientFeatureFlags?.claimDocUserFeatureFlags?.findIndex((x) => x.clientId == props.clientId) ?? 0) <= -1) {
      tempClientFeatureFlags.claimDocUserFeatureFlags?.push(
        ClaimDocUserFeatureFlagsDTO.create({ clientId: props.clientId, featureFlags: [] })
      );
    }
    const featureFlags = tempClientFeatureFlags?.claimDocUserFeatureFlags?.find(
      (x) => x.clientId == props.clientId
    )?.featureFlags;

    featureFlags?.forEach((x) => {
      if (baseFeatureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.remove(x);
      }
    });

    baseFeatureFlags?.forEach((x) => {
      if (featureFlags?.findIndex((y) => y.id == x.id) == -1) {
        featureFlags.push(x);
      }
    });

    setClientFeatureFlag(tempClientFeatureFlags);
  };

  const getBrokerEditor = (x: BrokerListDTO) => {
    if (activeTab == RoleName.BROKER + ',' + x.id) {
      return (
        <Space key={x.id} direction="vertical">
          <FeatureFlagEditor
            readOnly={props.readonly}
            featureFlagGroupName={'Service Providers'}
            baseFeatureFlag={getFeatureFlags(Role.BROKER)}
            onChange={(e) => changeBrokerFeatureFlag(e, x.id)}
          />
        </Space>
      );
    }
  };

  const getDpcEditor = (x: DpcListDTO) => {
    if (activeTab == RoleName.DPC + ',' + x.id) {
      return (
        <Space key={x.id} direction="vertical">
          <FeatureFlagEditor
            readOnly={props.readonly}
            featureFlagGroupName={'Service Providers'}
            baseFeatureFlag={getFeatureFlags(Role.DPC)}
            onChange={(e) => changeDpcFeatureFlag(e, x.id)}
          />
        </Space>
      );
    }
  };

  const getOtherCompanyEditor = (x: OtherCompanyListDTO) => {
    if (activeTab == RoleName.OTHERCOMPANY + ',' + x.id) {
      return (
        <Space key={x.id} direction="vertical">
          <FeatureFlagEditor
            readOnly={props.readonly}
            featureFlagGroupName={'Service Providers'}
            baseFeatureFlag={getFeatureFlags(Role.OTHERCOMPANY)}
            onChange={(e) => changeOtherCompanyFeatureFlag(e, x.id)}
          />
        </Space>
      );
    }
  };

  const getTpaEditor = (x: TpaListDTO) => {
    if (activeTab == RoleName.TPA + ',' + x.id) {
      return (
        <Space key={x.id} direction="vertical">
          <FeatureFlagEditor
            readOnly={props.readonly}
            featureFlagGroupName={'Service Providers'}
            baseFeatureFlag={getFeatureFlags(Role.TPA)}
            onChange={(e) => changeTpaFeatureFlag(e, x.id)}
          />
        </Space>
      );
    }
  };

  const getEmployerEditor = () => {
    if (activeTab ==RoleName.EMPLOYER + ',' + props.clientId) {
      return (
        <Space direction="vertical">
          <FeatureFlagEditor
            readOnly={props.readonly}
            featureFlagGroupName={'Service Providers'}
            baseFeatureFlag={getFeatureFlags(Role.EMPLOYER)}
            onChange={(e) => changeEmployerFeatureFlag(e)}
          />
        </Space>
      );
    }
  };

  const getClaimDOCUserEditor = () => {
    if (activeTab == RoleName.CLAIMDOC + ',' + props.clientId) {
      return (
        <Space direction="vertical">
          <FeatureFlagEditor
            readOnly={props.readonly}
            featureFlagGroupName={'Service Providers'}
            baseFeatureFlag={getFeatureFlags(Role.CLAIMDOC)}
            onChange={(e) => changeClaimDOCFeatureFlag(e)}
          />
        </Space>
      );
    }
  };

  const getFeatureFlags = (roleId: number) => {
    let featureFlags: FeatureFlagDTO[] = [];
    const activeRoleClient = activeTab.split(',')[1];
    switch (roleId) {
      case Role.BROKER:
        featureFlags =
          clientFeatureFlag?.brokerClientFeatureFlags?.find((y) => y.brokerId?.toString() == activeRoleClient)
            ?.featureFlags ?? [];
        break;
      case Role.EMPLOYER:
        featureFlags =
          clientFeatureFlag?.employerFeatureFlags?.find((y) => y.clientId == props.clientId)?.featureFlags ?? [];
        break;
      case Role.CLAIMDOC:
        featureFlags =
          clientFeatureFlag?.claimDocUserFeatureFlags?.find((y) => y.clientId == props.clientId)?.featureFlags ?? [];
        break;
      case Role.DPC:
        featureFlags =
          clientFeatureFlag?.dpcClientFeatureFlags?.find((y) => y.dpcId?.toString() == activeRoleClient)?.featureFlags ?? [];
        break;
      case Role.OTHERCOMPANY:
        featureFlags =
          clientFeatureFlag?.otherCompanyClientFeatureFlags?.find((y) => y.otherCompanyId?.toString() == activeRoleClient)
            ?.featureFlags ?? [];
        break;
      case Role.TPA:
        featureFlags =
          clientFeatureFlag?.tpaClientFeatureFlags?.find((y) => y.tpaId?.toString() == activeRoleClient)?.featureFlags ?? [];
        break;
    }

    return featureFlags;
  };

  const onTabChange = (activeTab: string) => {
    loadClientFeatureFlags(activeTab);
    setActiveTab(activeTab);
  };

  const tabs: any = [];

  tabs.push({
    label: 'ClaimDOC Member Advocate',
    key: RoleName.CLAIMDOC + ',' + props.clientId,
    children: getClaimDOCUserEditor(),
  });

  tabs.push({
    label: 'Employer',
    key: RoleName.EMPLOYER + ',' + props.clientId,
    children: getEmployerEditor(),
  });

  tabs.pushAll(brokerList.length == 0 ? [{
    label: 'Broker - ',
    key: RoleName.BROKER,
    disabled: true,
  }]  :
    brokerList.map((x) => {
      return {
        label: 'Broker - ' + x.name,
        key: RoleName.BROKER + ',' + x.id,
        children: getBrokerEditor(x),
      };
    })
  );

  tabs.pushAll(dpcList.length == 0 ? [{
    label: 'DPC - ',
    key: RoleName.DPC,
    disabled: true,
  }]  :
    dpcList.map((x) => {
      return {
        label: 'DPC - ' + x.name,
        key: RoleName.DPC + ',' + x.id,
        children: getDpcEditor(x),
      };
    })
  );

  tabs.pushAll(otherCompanyList.length == 0 ? [{
    label: 'Other - ',
    key: RoleName.OTHERCOMPANY,
    disabled: true,
  }]  :
    otherCompanyList.map((x) => {
      return {
        label: 'Other - ' + x.name,
        key: RoleName.OTHERCOMPANY + ',' + x.id,
        children: getOtherCompanyEditor(x),
      };
    })
  );

  tabs.pushAll(tpaList.length == 0 ? [{
    label: 'TPA - ',
    key: RoleName.TPA,
    disabled: true,
  }]  :
    tpaList.map((x) => {
      return {
        label: 'TPA - ' + x.name,
        key: RoleName.TPA + ',' + x.id,
        children: getTpaEditor(x),
      };
    })
  );

  return (
    <Spin spinning={loading}>
      <Row align="bottom" gutter={12}>
        <Col xs={12}></Col>
        {props.readonly === false ? (
          <Col style={{ textAlign: 'right' }}>
            <Space>
              <ConditionalPopconfirm
                title="Are you sure you want to reset all changes?"
                condition={changesPending}
                disabled={!changesPending}
                onConfirm={reset}
              >
                <Button htmlType="button" ghost shape="round" disabled={!changesPending}>
                  Reset
                </Button>
              </ConditionalPopconfirm>

              <Button onClick={saveChanges} type="primary" shape="round" disabled={!changesPending}>
                Save
              </Button>
            </Space>
          </Col>
        ) : null}
      </Row>

      <div>
        <Tabs activeKey={activeTab} onChange={onTabChange} items={tabs} tabPosition="top" />
      </div>
    </Spin>
  );
};

export default ClientConfig;
