// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class Permission {
    public static readonly ETL_PROVIDER_EXECUTESYNC: number = 1;
    public static readonly GENERAL_ACCOUNT_LOGIN: number = 2;
    public static readonly TOOLS_PROVIDERDIRECTORY_MAPVIEWBASE: number = 3;
    public static readonly ADMIN_CLIENT_LIST_VIEW: number = 4;
    public static readonly ADMIN_CLIENT_DETAILS_VIEW: number = 5;
    public static readonly ADMIN_TPA_LIST_VIEW: number = 6;
    public static readonly ADMIN_TPA_DETAILS_VIEW: number = 7;
    public static readonly ADMIN_BROKER_LIST_VIEW: number = 8;
    public static readonly ADMIN_BROKER_DETAILS_VIEW: number = 9;
    public static readonly ADMIN_USERS_LIST_VIEW: number = 10;
    public static readonly REPORTING_ACCESS_PERFORMANCEREPORT: number = 11;
    public static readonly REPORTING_ACCESS_COSTANALYSISREPORT: number = 12;
    public static readonly REPORTING_PERFORMANCEREPORTFILTER_ADMIN: number = 13;
    public static readonly REPORTING_PERFORMANCEREPORTFILTER_CLIENTSUPPORT: number = 14;
    public static readonly ADMIN_CLAIMDOC_ADMINFULLCLIENTACCESS: number = 15;
    public static readonly ADMIN_BROKER_DETAILS_EDIT: number = 16;
    public static readonly ADMIN_CLIENT_DETAILS_EDIT: number = 17;
    public static readonly ADMIN_CLIENT_CLAIMDOC_ACCESS_VIEW: number = 18;
    public static readonly ADMIN_PERMISSIONGROUPS_DETAILS_EDIT: number = 19;
    public static readonly TOOLS_PROVIDERDIRECTORY_VIEWBALANCEBILL: number = 20;
    public static readonly ADMIN_BROKER_SECURITY_VIEW: number = 21;
    public static readonly ADMIN_BROKER_SECURITY_EDIT: number = 22;
    public static readonly ADMIN_CLIENT_SECURITY_VIEW: number = 23;
    public static readonly ADMIN_CLIENT_SECURITY_EDIT: number = 24;
    public static readonly ADMIN_TPA_SECURITY_VIEW: number = 25;
    public static readonly ADMIN_TPA_SECURITY_EDIT: number = 26;
    public static readonly ADMIN_BROKER_CLIENTS_VIEW: number = 27;
    public static readonly ADMIN_BROKER_CLIENTS_EDIT: number = 28;
    public static readonly ADMIN_BROKER_DOMAINS_VIEW: number = 29;
    public static readonly ADMIN_BROKER_DOMAINS_EDIT: number = 30;
    public static readonly ADMIN_TPA_DETAILS_EDIT: number = 31;
    public static readonly ADMIN_TPA_CLIENTS_VIEW: number = 32;
    public static readonly ADMIN_TPA_CLIENTS_EDIT: number = 33;
    public static readonly ADMIN_TPA_DOMAINS_VIEW: number = 34;
    public static readonly ADMIN_TPA_DOMAINS_EDIT: number = 35;
    public static readonly ADMIN_PERMISSIONGROUPS_LIST_VIEW: number = 36;
    public static readonly ADMIN_PERMISSIONGROUPS_DETAILS_VIEW: number = 37;
    public static readonly ADMIN_USERS_DETAILS_VIEW: number = 38;
    public static readonly ADMIN_USERS_RELATIONSHIPS_VIEW: number = 39;
    public static readonly ADMIN_USERS_PERMISSIONGROUPS_VIEW: number = 40;
    public static readonly ADMIN_USERS_PERMISSIONGROUPS_EDIT: number = 41;
    public static readonly ADMIN_USERS_DETAILS_EDIT: number = 42;
    public static readonly ADMIN_CLIENT_CLAIMDOC_ACCESS_EDIT: number = 43;
    public static readonly ADMIN_CLIENT_TPA_VIEW: number = 44;
    public static readonly ADMIN_CLIENT_TPA_EDIT: number = 45;
    public static readonly ADMIN_CLIENT_BROKER_VIEW: number = 46;
    public static readonly ADMIN_CLIENT_BROKER_EDIT: number = 47;
    public static readonly ADMIN_CLIENT_ADMIN_EMAILS_VIEW: number = 48;
    public static readonly ADMIN_CLIENT_ADMIN_EMAILS_EDIT: number = 49;
    public static readonly ADMIN_CLIENT_CLIENTREPORTSETTINGS_VIEW: number = 50;
    public static readonly ADMIN_CLIENT_CLIENTREPORTSETTINGS_EDIT: number = 51;
    public static readonly ADMIN_USERS_MANAGEMFA: number = 52;
    public static readonly TOOLS_PROVIDERNOMINATION_ACCESS: number = 53;
    public static readonly ETL_PROVIDERNOMINATION_APIACCESS: number = 54;
    public static readonly ADMIN_EMPLOYERPINACCESS_VIEW: number = 55;
    public static readonly ADMIN_EMPLOYERPINACCESSLINKED_EDIT: number = 56;
    public static readonly GENERAL_EMPLOYERPINACCESS_CREATEPERSONALPINS: number = 57;
    public static readonly ADMIN_USERS_SERVICEACCOUNTMANAGER: number = 58;
    public static readonly ADMIN_PERMISSIONGROUPS_AUDIT: number = 59;
    public static readonly ETL_BROKER_EXECUTESYNC: number = 60;
    public static readonly TOOLS_MEMBERCARD_ACCESS: number = 61;
    public static readonly SUPPORT_ACTIONLOGS_FAILEDMEMBERREGISTRATIONS: number = 62;
    public static readonly ETL_CLIENT_EXECUTESYNC: number = 63;
    public static readonly ETL_TPA_EXECUTESYNC: number = 64;
    public static readonly ADMIN_TPA_INVITEUSERS: number = 66;
    public static readonly ADMIN_CLIENT_INVITEUSERS: number = 67;
    public static readonly TOOLS_BALANCEBILL_ACCESS: number = 68;
    public static readonly ADMIN_TOOLS_COMMUNICATIONTESTER: number = 69;
    public static readonly ETL_BALANCEBILL_APIACCESS: number = 70;
    public static readonly ETL_MEMBERCARD_EXPIRE: number = 71;
    public static readonly ETL_FAMILYUNIT_EXECUTESYNC: number = 72;
    public static readonly ADMIN_PROVIDER_LIST_VIEW: number = 73;
    public static readonly ADMIN_PROVIDER_DETAIL_VIEW: number = 74;
    public static readonly TOOLS_PROVIDERDIRECTORY_VIEWAGREEMENT: number = 75;
    public static readonly TOOLS_PROVIDERDIRECTORY_LINKING: number = 76;
    public static readonly ETL_USERS_SYNC_CLAIMDOC: number = 77;
    public static readonly TOOLS_SIDENAV_DEMOMODELINK: number = 79;
    public static readonly GENERAL_EDUCATION_ACCESS: number = 81;
    public static readonly ADMIN_BROKER_INVITEUSERS: number = 82;
    public static readonly DOCUMENTS_SIDENAV_ACCESS: number = 83;
    public static readonly TOOLS_CLAIMSEARCH_ACCESS: number = 84;
    public static readonly DOCUMENTS_MRF_ACCESS: number = 85;
    public static readonly DOCUMENTS_PNF_ACCESS: number = 86;
    public static readonly DOCUMENTS_BALANCEBILL_ACCESS: number = 87;
    public static readonly ADMIN_DPC_LIST_VIEW: number = 88;
    public static readonly ADMIN_DPC_DETAILS_VIEW: number = 89;
    public static readonly ADMIN_DPC_INVITEUSERS: number = 90;
    public static readonly ADMIN_OTHERCOMPANY_LIST_VIEW: number = 91;
    public static readonly ADMIN_OTHERCOMPANY_DETAILS_VIEW: number = 92;
    public static readonly ADMIN_OTHERCOMPANY_INVITEUSERS: number = 93;
    public static readonly ETL_DPC_EXECUTESYNC: number = 94;
    public static readonly ETL_OTHERCOMPANY_EXECUTESYNC: number = 95;
    public static readonly ADMIN_DPC_CLIENTS_VIEW: number = 96;
    public static readonly ADMIN_DPC_SECURITY_VIEW: number = 97;
    public static readonly ADMIN_DPC_DETAILS_EDIT: number = 98;
    public static readonly ADMIN_DPC_CLIENTS_EDIT: number = 99;
    public static readonly ADMIN_DPC_SECURITY_EDIT: number = 100;
    public static readonly ADMIN_OTHERCOMPANY_CLIENTS_VIEW: number = 101;
    public static readonly ADMIN_OTHERCOMPANY_SECURITY_VIEW: number = 102;
    public static readonly ADMIN_OTHERCOMPANY_DETAILS_EDIT: number = 103;
    public static readonly ADMIN_OTHERCOMPANY_CLIENTS_EDIT: number = 104;
    public static readonly ADMIN_OTHERCOMPANY_SECURITY_EDIT: number = 105;
    public static readonly ADMIN_CLIENT_DPC_VIEW: number = 106;
    public static readonly ADMIN_CLIENT_DPC_EDIT: number = 107;
    public static readonly ADMIN_CLIENT_OTHERCOMPANY_VIEW: number = 108;
    public static readonly ADMIN_CLIENT_OTHERCOMPANY_EDIT: number = 109;
    public static readonly UTILITIES_DOCSCAN_ACCESS: number = 110;
    public static readonly TOOLS_PRICINGTOOL_ACCESS: number = 111;
    public static readonly DOCUMENTS_PNF_DISPLAYSTATUS: number = 112;
    public static readonly UTILITIES_MAILROOM_FILE_VIEW: number = 113;
    public static readonly UTILITIES_MAILROOM_FILE_EDIT: number = 114;
    public static readonly UTILITIES_MAILROOM_ACCOUNT_VIEW: number = 115;
    public static readonly UTILITIES_MAILROOM_ACCOUNT_EDIT: number = 116;
    public static readonly SUPPORT_CLIENTMEMBERSEARCH_BASEACCESS: number = 117;
    public static readonly ADMIN_CLAIMDOC_FULLNONRESTRICTEDCLIENTACCESS: number = 118;
    public static readonly SUPPORT_CLIENTMEMBERSEARCH_FULLCLIENTACCESS: number = 119;
    public static readonly ADMIN_CLIENT_CONFIG_EDIT: number = 120;
    public static readonly ADMIN_CLIENT_CONFIG_VIEW: number = 121;
    public static readonly ADMIN_EMPLOYERPINACCESSUNLINKED_EDIT: number = 122;
    public static readonly GENERAL_EMPLOYERPINACCESS_EDITPERMISSIONGROUP: number = 123;
}
