import { Button, Col, Layout, Row, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MemberCardApiService from '../../api/MemberCardApiService';
import MemberCardDisplay from '../../components/memberCard/MemberCardDisplay';
import PageTitle from '../../components/shared/PageTitle';
import Routes from '../../consts/Routes';
import MemberCardDTO from '../../models/MemberCardDTO';
import MobileUtil from '../../utils/MobileUtil';

const Content = Layout.Content;
const { Title } = Typography;

const MemberCardsPage = () => {
  const [memberCards, setMemberCards] = useState<{ [cardTypeName: string]: MemberCardDTO[] }>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const isMobile = () => MobileUtil.getMediaQuery()?.matches;

  useEffect(() => {
    setLoading(true);
    MemberCardApiService.getMemberCardsForUser()
      .then((cards) => {
        const cardsByType = {};
        cards.forEach((card) => {
          const cardTypeName = card.cardTypeName ?? 'Cards';
          if (!cardsByType[cardTypeName]) {
            cardsByType[cardTypeName] = [];
          }
          cardsByType[cardTypeName].push(card);
        });

        setMemberCards(cardsByType);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const mobileRowStyle = isMobile()
    ? { style: { display: 'flex', justifyContent: 'center', alignItems: 'center' } }
    : {};

  const cardCount = Object.keys(memberCards).length;

  const newCardLinkButton = () => {
    return (
      <Link to={Routes.generate(Routes.MEMBER_CARDS_CREATE)}>
        <Button type="primary" shape="round" style={{ marginRight: '16px' }} disabled={cardCount >= 10}>
          Add Card
        </Button>
      </Link>
    );
  };

  return (
    <Content style={{ height: '100%' }} className="content-padding">
      <Spin spinning={isLoading}>
        <Row style={{ height: '100%' }}>
          <Col xs={24} sm={24} md={24}>
            <Row className="content-header" justify="space-between">
              <Col>
                <PageTitle title="Member Cards" />
              </Col>
              <Col>{newCardLinkButton()}</Col>
            </Row>
          </Col>
        </Row>

        {cardCount > 0 ? (
          Object.keys(memberCards).map((type) => {
            const cards = memberCards[type];
            return (
              <div key={type}>
                <h2>{type} Cards</h2>
                <Row className="member-card-row" {...mobileRowStyle}>
                  {cards.map((card) => (
                    <Col key={card.id} style={{ padding: '0 20px 20px 0' }}>
                      <MemberCardDisplay card={card} />
                    </Col>
                  ))}
                </Row>
              </div>
            );
          })
        ) : (
          <div>
            <Space direction="vertical">
              <Title level={5} type="secondary">
                You have no member cards
              </Title>
              {newCardLinkButton()}
            </Space>
          </div>
        )}
      </Spin>
    </Content>
  );
};

export default MemberCardsPage;
