import { EditOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Layout, notification, Popconfirm, Row, Space, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import MemberCardApiService from '../../api/MemberCardApiService';
import Routes from '../../consts/Routes';
import MemberCardDTO from '../../models/MemberCardDTO';
import FileUploadUtil from '../../utils/HasFileUploadUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import PageTitle from '../shared/PageTitle';
import EditMemberCard from './EditMemberCard';
import MemberCardDisplay from './MemberCardDisplay';

const Content = Layout.Content;

interface MemberCardRouteProps {
  id?: string;
}

const MemberCardsDetailPage = () => {
  const routeProps = useParams<MemberCardRouteProps>();
  const idParam = routeProps.id || '0';
  const parsedId = parseInt(idParam);

  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [editing] = useState(true); // isNaN(parsedId) || parsedId <= 0
  const [cardId, setCardId] = useState(isNaN(parsedId) ? 0 : parsedId);
  const [card, setMemberCard] = useState<MemberCardDTO>(MemberCardDTO.create({ cardTypeId: undefined }));

  useEffect(() => {
    if (cardId > 0) {
      setLoading(true);
      MemberCardApiService.getMemberCardsForUser(cardId)
        .then((cards) => {
          setMemberCard(cards[0]);
          form.resetFields();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [cardId]);

  useEffect(() => {
    const idParam = routeProps.id || '0';
    const cardId = parseInt(idParam);
    if (isNaN(cardId) || cardId == 0) {
      setCardId(0);
    } else {
      setCardId(cardId);
    }
  }, [routeProps.id]);

  const handleSave = (dto: MemberCardDTO) => {
    setLoading(true);

    dto.id = cardId;
    const request = FileUploadUtil(dto, 'request', ['frontFile', 'backFile']);

    MemberCardApiService.saveMemberCard(request)
      .then((res) => {
        if (res.succeeded) {
          HistoryUtil.replace(Routes.MEMBER_CARDS);
          notification.success({ message: 'Member card saved' });
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'Failed to save member card' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCard = () => {
    setLoading(true);
    MemberCardApiService.deleteMemberCard(cardId)
      .then(() => {
        HistoryUtil.replace(Routes.MEMBER_CARDS);
      })
      .catch(() => {
        notification.error({ message: 'Failed to delete.' });
        setLoading(false);
      });
  };

  const cardName = card.id > 0 ? card.cardName : 'New Card';

  return (
    <Content style={{ height: '100%' }} className="content-padding">
      <Spin spinning={loading}>
        <Row justify="space-between" style={{ marginBottom: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Routes.MEMBER_CARDS}>Member Cards</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{cardName}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row style={{ height: '100%' }}>
          <Col xs={24} sm={24} md={24}>
            <Row className="content-header" justify="space-between">
              <Col flex={1}>
                <PageTitle title={cardName} />
              </Col>
              <Col flex={0}>
                {editing ? (
                  <Space>
                    {cardId != 0 ? (
                      <Popconfirm title="Are you sure you want to delete this member card?" onConfirm={deleteCard}>
                        <Button shape="round">Delete</Button>
                      </Popconfirm>
                    ) : null}
                    <Button shape="round" onClick={() => HistoryUtil.push(Routes.MEMBER_CARDS)}>
                      Cancel
                    </Button>
                    <Button
                      shape="round"
                      type="primary"
                      onClick={() => {
                        form.validateFields().then(() => form.submit());
                      }}
                    >
                      Save
                    </Button>
                  </Space>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          {editing ? (
            <EditMemberCard key={card.id} card={card} onSave={handleSave} form={form} />
          ) : (
            <Col>
              <MemberCardDisplay key={card.id} card={card} />
            </Col>
          )}
        </Row>
        {editing ? null : (
          <NavLink to={Routes.generate(Routes.MEMBER_CARDS_DETAIL, { id: cardId })} style={{ paddingTop: 10 }}>
            <Button shape="round" icon={<EditOutlined />} ghost>
              Edit Member Card
            </Button>
          </NavLink>
        )}
      </Spin>
    </Content>
  );
};

export default MemberCardsDetailPage;
