// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface MailroomAccountDetailDTO { 
    id: number;
    account: string | null;
    baseUrl: string | null;
    partner: string | null;
    partnerCode: string | null;
    archived: boolean;
    notes: string | null;
    contacts: string | null;
    accountType: string | null;
    specialSecurity: string | null;
}
const MailroomAccountDetailDTO = {
    create: (initValues?: Partial<MailroomAccountDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            account: null,
            baseUrl: null,
            partner: null,
            partnerCode: null,
            archived: false,
            notes: null,
            contacts: null,
            accountType: null,
            specialSecurity: null,
        },
        initValues);
    }
};

export default MailroomAccountDetailDTO;