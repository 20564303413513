import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Menu,
  notification,
  Popconfirm,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, NavLink, Route, Switch, useParams } from 'react-router-dom';
import DpcApiService from '../../api/DpcApiService';
import DpcClientAccessEditor from '../../components/admin/DPCs/DpcClientAccessEditor';
import DpcClientAccessList from '../../components/admin/DPCs/DpcClientAccessList';
import DpcSecurity from '../../components/admin/DPCs/DpcSecurity';
import PageTitle from '../../components/shared/PageTitle';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import DpcDetailDTO from '../../models/DpcDetailDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import Renderers from '../../utils/Renderers';
import DpcInviteUsers from '../../components/admin/DPCs/DpcInviteUsers';
import parseIntOrDefault from '../../utils/ParseIntOrDefault';
import { CloseCircleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
import { useForm } from 'antd/lib/form/Form';
import PermissionGuard from '../../components/shared/PermissionGuard';
import PageStayPrompt from '../../utils/PageStayPrompt';

const Content = Layout.Content;

interface DpcRouteProps {
  id: string;
  tab?: string;
}

const DpcDetailPage = () => {
  const routeProps = useParams<DpcRouteProps>();
  const id = parseIntOrDefault(routeProps.id);

  if (id <= 0) {
    HistoryUtil.replace(Routes.ADMIN_DPC_LIST);
  }

  const [dpcDetails, setDpcDetails] = useState<DpcDetailDTO>(DpcDetailDTO.create());
  const [selectedTab, setSelectedTab] = useState(routeProps.tab || 'about');
  const [loading, setLoading] = useState(false);
  const [editingNotes, setEditingNotes] = useState(false);
  const [dpcId, setDpcId] = useState(id);
  const [form] = useForm();
  const hasPermission = useHasPermission();

  const fetch = (id: number) => {
    setLoading(true);
    DpcApiService.getDPCDetails(id)
      .then((res) => {
        setDpcDetails(res);
      })
      .catch((err) => {
        notification.error({
          message: 'Error loading DPC',
          description: err?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const id = parseIntOrDefault(routeProps.id);

    if (id <= 0) {
      HistoryUtil.replace(Routes.ADMIN_DPC_LIST);
    }

    setDpcId(id);
    fetch(id);
  }, [routeProps.id]);

  const saveNote = () => {
    setLoading(true);

    const note = form.getFieldValue('note');
    DpcApiService.saveDPCNotes(dpcId, note)
      .then(() => {
        notification.success({ message: 'Notes Saved' });
        setDpcDetails({ ...dpcDetails, notes: note });
        setEditingNotes(false);
      })
      .catch(() => {
        notification.success({ message: 'Failed to Save', description: 'Please refresh and try again.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSelectedTab(routeProps.tab || 'about');
  }, [routeProps.tab]);

  const deleteDpc = () => {
    setLoading(true);
    DpcApiService.deleteDpc(dpcId)
      .then(() => {
        notification.success({ message: 'DPC Deleted' });
        HistoryUtil.replace(Routes.ADMIN_DPC_LIST);
      })
      .catch(() => {
        notification.error({ message: 'Failed to delete DPC.' });
        setLoading(false);
      });
  };

  const detailsRender = () => (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} xl={8}>
          <Card className="ant-card-background ant-card-shadow" bordered={false}>
            <Typography.Title level={3}>Details</Typography.Title>
            <Row gutter={[16, 16]}>
              <Col flex={0}>
                <strong>DPC ID:</strong>
                <br />
                <strong>DPC Code:</strong>
                <br />
                <strong>Phone:</strong>
                <br />
                <strong>Website:</strong>
              </Col>
              <Col flex={1}>
                {dpcDetails?.claimDocId}
                <br />
                {dpcDetails?.dpcCode}
                <br />

                {dpcDetails?.phoneNumber ? Renderers.phoneNumberLink(dpcDetails?.phoneNumber) : null}
                <br />
                {dpcDetails?.url ? Renderers.urlLink(dpcDetails.url) : null}
                <br />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12} xl={8}>
          <Card className="ant-card-background ant-card-shadow" bordered={false} style={{ height: '100%' }}>
            <Typography.Title level={3}>Address</Typography.Title>
            <Text style={{ whiteSpace: 'pre-wrap' }}>{Renderers.address(dpcDetails)}</Text>
          </Card>
        </Col>
        <Col xs={24} xl={16}>
          <Card className="ant-card-background ant-card-shadow" bordered={false}>
            <Row align="bottom" style={{ paddingBottom: '0.5em' }}>
              <Col flex={0}>
                <Typography.Title level={3} style={{ marginBottom: 0 }}>
                  Notes
                </Typography.Title>
              </Col>
              <Col flex={1} style={{ textAlign: 'right' }}>
                <PermissionGuard permissionId={Permission.ADMIN_DPC_DETAILS_EDIT}>
                  <Space direction="horizontal">
                    {!editingNotes ? (
                      <Button icon={<EditOutlined />} type="link" onClick={() => setEditingNotes(true)} />
                    ) : (
                      <>
                        <Button icon={<SaveOutlined />} type="link" onClick={() => saveNote()} />
                        <Button icon={<CloseCircleOutlined />} type="link" onClick={() => setEditingNotes(false)} />
                      </>
                    )}
                  </Space>
                </PermissionGuard>
              </Col>
            </Row>

            {!editingNotes ? (
              <div style={{ minHeight: 140, whiteSpace: 'pre-wrap' }}>{dpcDetails?.notes}</div>
            ) : (
              <>
                <PageStayPrompt when={true} />
                <Form initialValues={{ note: dpcDetails?.notes }} form={form}>
                  <FormItem name="note">
                    <Input.TextArea rows={6} />
                  </FormItem>
                </Form>
              </>
            )}
          </Card>
        </Col>
      </Row>
      {dpcId > 0 && (
        <PermissionGuard permissionId={Permission.ADMIN_DPC_DETAILS_EDIT}>
          <Popconfirm title="Are you sure you want to delete this DPC?" onConfirm={deleteDpc}>
            <Button style={{ marginTop: 15 }} danger>
              Delete DPC
            </Button>
          </Popconfirm>
        </PermissionGuard>
      )}
    </Spin>
  );

  const tabs = [];

  if (hasPermission(Permission.ADMIN_DPC_DETAILS_VIEW)) {
    tabs.push(
      <Menu.Item key="about">
        <NavLink to={Routes.generate(Routes.ADMIN_DPC_DETAILS, { id: dpcId })}>DPC Information</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_DPC_CLIENTS_VIEW)) {
    tabs.push(
      <Menu.Item key="clients">
        <NavLink to={Routes.generate(Routes.ADMIN_DPC_DETAILS_CLIENTS, { id: dpcId })}>Clients</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_DPC_SECURITY_VIEW)) {
    tabs.push(
      <Menu.Item key="security">
        <NavLink to={Routes.generate(Routes.ADMIN_DPC_DETAILS_SECURITY, { id: dpcId })}>Security</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_DPC_INVITEUSERS)) {
    tabs.push(
      <Menu.Item key="invite">
        <NavLink to={Routes.generate(Routes.ADMIN_DPC_INVITE_USERS, { id: dpcId })}>Invite Users</NavLink>
      </Menu.Item>
    );
  }

  return (
    <Content className="content-padding">
      <Row>
        <Col xs={24}>
          <Row justify="space-between" style={{ marginBottom: '12px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={Routes.ADMIN_DPC_LIST}>DPCs</Link>
              </Breadcrumb.Item>
              {loading ? null : <Breadcrumb.Item>{dpcDetails?.name ?? 'New DPC'}</Breadcrumb.Item>}
            </Breadcrumb>
          </Row>
          <PageTitle
            title={loading ? '' : dpcDetails?.name ?? 'New DPC'}
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
          />

          <Row>
            <Col xs={24}>
              <Menu mode="horizontal" style={{ marginBottom: 15 }} selectedKeys={[selectedTab]}>
                {tabs}
              </Menu>

              <Switch>
                <Route
                  path={Routes.ADMIN_DPC_DETAILS_CLIENTS}
                  render={(props) =>
                    props.match.params.clientId ? (
                      <DpcClientAccessEditor dpcId={dpcId} clientId={props.match.params.clientId} />
                    ) : (
                      <DpcClientAccessList dpcId={dpcId} />
                    )
                  }
                />
                <Route path={Routes.ADMIN_DPC_DETAILS_SECURITY} render={() => <DpcSecurity dpcId={dpcId} />} />

                <Route
                  path={Routes.ADMIN_DPC_INVITE_USERS}
                  exact={true}
                  render={() => <DpcInviteUsers dpcId={dpcId} />}
                />

                <Route path={Routes.ADMIN_DPC_DETAILS} exact={true} render={detailsRender} />
              </Switch>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

export default DpcDetailPage;
